import React, { useCallback } from 'react'
import { Route, Routes } from 'react-router-dom';
import { Login, ForgotPassword } from '../pages/account'
import ResetPassword from '../pages/account/ResetPassword';
import { AdminLogin } from '../pages/superadmin/account';
import { CreateNewPassword } from '../pages/account/CreateNewPassword';
import { AuthService } from '../api-services';
import { toast } from 'react-toastify';
const Account = (props) => {

    const onSubmitCreateNewPassword = useCallback((payload, resetForm) => {
        const { encryptPayload, newPassword, subdomain } = payload;
        const { userId } = encryptPayload;

        const params = {
            userId: userId,
            newPassword: newPassword,
            subdomain: subdomain
        };

        AuthService.createNewPassword(params)
            .then((res) => {
                toast.success("New password created successfully!");
                const currentUrl = window.location.href;

                if (currentUrl.includes('/account/createNewPassword/')) {
                    const urlParts = currentUrl.split('/account/createNewPassword/');
                    const updatedUrl = urlParts[0] + '/account/createNewPassword/';
                    window.history.replaceState({}, document.title, updatedUrl);
                }

                resetForm();
            })
            .catch((err) => {
                const errorMessage = err?.response?.data?.message || 'An error occurred. Please try again.';
                toast.error(errorMessage);
                resetForm(); // Res
            });
    }, []);
    return <Routes>
        <Route path="login" element={<Login />} />
        <Route path="createNewPassword/:token"
            element={<CreateNewPassword hideElement={true} onSubmitCreateNewPassowrd={onSubmitCreateNewPassword} />} />
        <Route path="admin/login" element={<AdminLogin />} />

        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="reset-password/:token" element={<ResetPassword />} />
    </Routes>
}







export default Account;