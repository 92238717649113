import { Subject } from 'rxjs'

import Constants from '../Constants';

export default class AuthService {

    static LoggedInUserDetails = new Subject();

    static UpdateLoggedInUserDetails = (data) => {
        this.LoggedInUserDetails.next(data);
    }

    static GetLoggedInUserData = () => {
        if(Constants.getAuthtoken()?.token) {
         const token = JSON.parse(Constants.decrypt(Constants.getAuthtoken()?.token))
         const parts = token.split('.');
         const payload = JSON.parse(window.atob(parts[1]));
    
        const profileImageUrl = `${process.env.REACT_APP_API_BASE_URL}/api/auth/profileImage/${payload?.id}`
         return {...payload, profileImageUrl};
        }
    
    }

    static UpdateLoggedInUserSession = (token) => {
        localStorage.setItem("authtoken", Constants.encrypt(JSON.stringify(token)));
        
        const updatedUserData = this.GetLoggedInUserData();
        this.UpdateLoggedInUserDetails(updatedUserData);
    }

    static checkloggedInUserFirstTime = (authtoken) => {
        const parts = authtoken.split('.');
        const payload = JSON.parse(window.atob(parts[1])); 
        return payload
    }

}