import { HashRouter, Route } from 'react-router-dom';
import { BrowserRouter, Routes } from 'react-router-dom';
import { Home as HomeLayout, Account as AccountLayout } from './route-layouts';
import AccountRoutes from './routes/Account'
import HomeRoutes from './routes/Home';
import ApiTestComponent from './ApiTestComponent';
import PublicRoute from './routes/publicRoute';
import ProtectedRoute from './routes/protactiveRoute';
import AdminRoute from './superadminRoute/route'
import AdminLayout from './superadminRoute/router-layout/Home'
import AdminProtectedRoute from './routes/AdminProtectedRoute';
import SearchPdfText from './pages/pdf-preview/SearchPdfText';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PageNotFound from './pages/PageNotFound ';
const Routess = () => {
  return (
    <>
    <ToastContainer 
    position="top-right"
    />
    <BrowserRouter>
      <Routes>
        <Route path="api-test" element={<ApiTestComponent />} />
        <Route path="" element={<ProtectedRoute />} >
        <Route path="/patient/searchWord" element={<SearchPdfText />} />
          <Route path="" element={<HomeLayout />} >
            <Route path="*" element={<HomeRoutes />} />
          </Route>
        </Route>
        <Route
          path='admin'
          element={
            <AdminProtectedRoute />}
        >
           <Route path="/admin/searchWord" element={<SearchPdfText />} />
           <Route path="" element={<AdminLayout />} >
           <Route path="*" element={<AdminRoute />} />
           </Route>
        </Route>
        <Route path="account" element={<PublicRoute />}>
          <Route element={<AccountLayout />}>
            <Route path="*" element={<AccountRoutes />} />
          </Route>
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
    </>

  );
}

export default Routess;