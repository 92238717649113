import { Navigate, Outlet, useLocation } from "react-router-dom";
import LoggedInUserService from '../services/AuthService'
import Constants from '../Constants';

const AdminProtectedRoute = ({ redirectPath = '/account/admin/login' }) => {
    const location = useLocation(); 
    const tdata = LoggedInUserService.GetLoggedInUserData();
    if (!Constants.getAuthtoken()?.isToken && tdata?.role === 1) {
        return <Navigate to={redirectPath}
            replace
            state={{ from: location }} />;
    }
    
    if(tdata!==undefined & tdata?.role === 1 || tdata?.email === 'mclaren@gmail.com'){
        return <Outlet />;
    }
    else if(tdata!==undefined &&tdata?.role === 0) {
        return <Navigate to={'/patient/list'}
             replace
             state={{ from: location }} />;
     }

};
export default AdminProtectedRoute;