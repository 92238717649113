import axios from 'axios';
import AppConfig from '../AppConfig';
import Constants from '../Constants';
import { headers } from './index';
class SuperAdminService {
    static AddHospital = (payloadData) => {
        const url = `${AppConfig.ApiBaseUrl}${Constants.ApiUrl.superAdmin.create}`;
        return axios.post(url, payloadData, { ...headers })
    }
    static GetHospital = (pageNumber, limit, searchText, type) => {
        let url = `${AppConfig.ApiBaseUrl}${Constants.ApiUrl.superAdmin.getHospital}`;
        const queryParams = [];
        if (pageNumber != null) {
            queryParams.push(`pageNumber=${pageNumber}`);
        }

        if (limit != null) {
            queryParams.push(`limit=${limit}`);
        }
        if (searchText) {
            queryParams.push(`searchText=${searchText}`);
        }
        if (type) {
            queryParams.push(`type=buildingList`);
        }
        if (queryParams.length > 0) {
            url += `?${queryParams.join('&')}`;
        }


        return axios.get(url, { ...headers }).then(resp => resp.data);
    }

    static deleteHospital = (id) => {
        const url = `${AppConfig.ApiBaseUrl}${Constants.ApiUrl.superAdmin.deleteHospital}/${id}`;
        return axios.delete(url, { ...headers }).then(resp => resp.data);
    }

    static UpdateHospital = (payloadData, id) => {
        const url = `${AppConfig.ApiBaseUrl}${Constants.ApiUrl.superAdmin.update}/${id}`;
        return axios.put(url, payloadData, { ...headers })
    }

    static SwitchFacility = (id) => {
        const url = `${AppConfig.ApiBaseUrl}${Constants.ApiUrl.superAdmin.switchHospital}/${id}`;
        return axios.get(url, { ...headers }).then(resp => resp.data);
    }


}

export default SuperAdminService;


