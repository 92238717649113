import React, { useState } from 'react';
import { Field, Formik, Form } from 'formik'
import * as Yup from 'yup'
import { TextInput, FileUpload } from '../../../../components';
import InputMask from 'react-input-mask'

const AddHospitalDetailForm = ({ onSubmit, onReportUpload, onCancel, editFData }) => {
  const [fileName, setFileName] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const onFileSelected = async (files) => {
    setFileName(files?.file?.name);
    onReportUpload(files.file)
  }


  const validationSchema = Yup.object().shape({
    hospitalName: Yup.string().required('Facility name is required'),
    // hospitalEmail: Yup.string().required('Facility email is required').email('Please enter a valid email'),
    hospitalPhoneNumber: Yup.string().required('PhoneNumber is required'),
    hospitalAddress: Yup.string().required('FacilityAddress is required'),
    firstName: Yup.string().required('firstName is required'),
    lastName: Yup.string().required('lastName is required'),
    contactNumber: Yup.string().required('contactNumber is required'),
    contactEmail: Yup.string().required('contactEmail is required'),
    subdomain: Yup.string()
      .matches(/^(?!-)[A-Za-z0-9-]{1,63}(?<!-)$/, 'Invalid subdomain format')
      .required('Subdomain is required'),
    // dataBaseName: Yup.string().required('dataBaseName is required'),
    dataBasePassword: Yup.string().required('Password is required').min(8, 'Password must be at least 8 characters long'),
    chatGptApiKey: Yup.string().required('chatGptApiKey is required'),
  })


  return (
    <Formik
      initialValues={{
        hospitalName: editFData?.hospitalName || '',
        //   hospitalEmail: '',
        hospitalPhoneNumber: editFData?.hospitalPhoneNumber || "",
        hospitalAddress: editFData?.hospitalAddress || "",
        firstName: editFData?.firstName || "",
        lastName: editFData?.lastName || "",
        contactNumber: editFData?.contactNumber || "",
        contactEmail: editFData?.contactEmail || "",
        subdomain: editFData?.subdomain || "",
        dataBaseName: editFData?.dataBaseName || "",
        // bucketName :"",
        // dataBaseUser:"",
        // databaseIP :"",
        dataBasePassword: editFData?.dataBasePassword || "",
        chatGptApiKey: editFData?.chatGptApiKey || ""

      }}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={(values, { setErrors }) => {
        values.dataBaseName = values.subdomain
        values.id = editFData?._id
        onSubmit(values, setErrors);
      }}
    >
      {(formikProps) => (
        <Form>
          <div className="add-form p-0">
            <div className="card">
              <div className="p-4">
                <h2 className="title">Facility  Detail
                </h2>
                <div className="row pt-3">
                  {/* <div className="form-group mb-10">
                    <div className="col-md-12">
                      <div className="form-group mb-0">
                        <div className="file-upload text-center">
                          <Field admin={true} name="hospitalLogo" component={FileUpload} multiplefileUpload={false} onFileSelected={onFileSelected} />
                          <i className="fa-solid fa-upload"></i>
                          <br />
                          <span className='ml-4'>Hospital Logo</span>
                          <p> {fileName ? fileName : "Upload file type .pdf, .jpg, .png"}</p>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className="col-6">
                    <div className="form-group">
                      <Field id="hospitalName" name="hospitalName" placeholder="Enter here" label="Facility Name" component={TextInput} />
                    </div>
                  </div>
                  {/* <div className="col-6">
                    <div className="form-group">
                      <Field id = "hospitalEmail" name="hospitalEmail" placeholder="Enter here" label="Hospital Email" component={TextInput} />
                    </div>
                  </div> */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <InputMask
                        mask="+1 999 999 99 99"
                        disabled={false}
                        maskChar=" "
                        value={formikProps.values.hospitalPhoneNumber}
                        onChange={(e) => {
                          formikProps.setFieldValue('hospitalPhoneNumber', e.target.value);
                        }}
                      >
                        {({ input }) => (
                          <Field
                            id="hospitalPhoneNumber"
                            name="hospitalPhoneNumber"
                            placeholder="Enter here"
                            label="Facility Phone no"
                            component={TextInput}
                            {...input}
                          />
                        )}
                      </InputMask>

                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <Field id="hospitalAddress" name="hospitalAddress" placeholder="Enter here" label="Facility Address" component={TextInput} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-4">
                <h2 className="title">Contact Person Detail
                </h2>
                <div className="row pt-3">
                  <div className="col-6">
                    <div className="form-group">
                      <Field id="firstName" name="firstName" placeholder="Enter here" label="First Name" component={TextInput} />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <Field id="lastName" name="lastName" placeholder="Enter here" label="Last Name" component={TextInput} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <InputMask
                        mask="+1 999 999 99 99"
                        disabled={false}
                        maskChar=" "
                        value={formikProps.values.contactNumber}
                        onChange={(e) => {
                          formikProps.setFieldValue('contactNumber', e.target.value);
                        }}
                      >
                        {({ input }) => (
                          <Field
                            id="contactNumber"
                            name="contactNumber"
                            placeholder="Enter here"
                            label="Contact Phone Number"
                            component={TextInput}
                            {...input}
                          />
                        )}
                      </InputMask>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <Field disabled={editFData !== undefined && editFData != null} id="contactEmail" name="contactEmail" placeholder="Enter here" label="Email Address" component={TextInput} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group"  style={{ position : "relative"}}>
                      <Field id="dataBasePassword" name="dataBasePassword" type={showPassword ? "text" : "password"} placeholder="Enter here" label="Login Password" component={TextInput} />
                      <span
                        onClick={() => setShowPassword(!showPassword)}
                        style={{
                          position: 'absolute',
                          right: '10px',
                          top: '57px',
                          transform: 'translateY(-50%)',
                          cursor: 'pointer'
                        }}
                      >
                        <i className={showPassword ? "fa fa-eye" : "fa fa-eye-slash"}></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-4">
                <h2 className="title">Facility Domain Detail
                </h2>
                <div className="row pt-3">
                  <div className="col-6">
                    <div className="form-group">
                      <Field disabled={editFData !== undefined && editFData != null} id="subdomain" name="subdomain" placeholder="Enter here" label={`SubDomain Name    (xxx.${process.env.REACT_APP_API_DOMAIN_URL})`} component={TextInput} />
                      <p>eg : my-subdomain, valid123, test-123 , vinyard</p>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <Field value={formikProps.values.subdomain} disabled id="dataBaseName" name="dataBaseName" placeholder="Enter here" label="DataBase Name" component={TextInput} />
                    </div>
                  </div>
                  {/* <div className="col-6">
                    <div className="form-group">
                      <Field id="bucketName" name="bucketName" placeholder="Enter here" label="Bucket Name" component={TextInput} />
                    </div>
                  </div> */}
                  {/* <div className="col-md-6">
                    <div className="form-group">
                      <Field name="dataBaseUser" placeholder="Enter here" label="DataBase User" component={TextInput} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <Field name="databaseIP" placeholder="Enter here" label="DataBase Ip" component={TextInput} />
                    </div>
                  </div> */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <Field id="chatGptApiKey" name="chatGptApiKey" placeholder="Enter here" label="ChatGpt key" component={TextInput} />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="btns text-center">
              <button className="btn-theme" type="submit"> {editFData !== undefined && editFData != null ? "Update" : "Save"}</button>
              <button className="btn-secondary form-buttons-margin" onClick={(e) => { e.preventDefault(); onCancel() }} >Cancel</button>
            </div>
          </div>
        </Form>
      )
      }
    </Formik>

  )


}

export default AddHospitalDetailForm;