import axios from 'axios';
import AppConfig from '../AppConfig';
import Constants from '../Constants';
import { headers } from './index';
class PatientService {
    static AddPatient = (payloadData) => {
        const url = `${AppConfig.ApiBaseUrl}${Constants.ApiUrl.create}`;
        return axios.post(url, payloadData ,{...headers})
    }

    static GenerateCaseDocsAndGetDocsUrls = (payloadData) => {
        const url = `${AppConfig.ApiBaseUrl}${Constants.ApiUrl.generate}`;
        return axios.post(url, payloadData, {
            'Content-Type': 'multipart/form-data',
            ...headers
        })
    }

    static GetPatient = (pageNumber , limit , searchText , distinctSearch , tokenPayload) => {
        let url = `${AppConfig.ApiBaseUrl}${Constants.ApiUrl.case.getPatient}`
        const queryParams = [];
        if (pageNumber != null) {
            queryParams.push(`pageNumber=${pageNumber}`);
        }

        if (limit != null) {
            queryParams.push(`limit=${limit}`);
        }
        if (searchText) {
            queryParams.push(`searchText=${searchText}`);
        }
        if(distinctSearch){
            queryParams.push(`distinctSearch=${distinctSearch}`); 
        }
        if(tokenPayload){
            queryParams.push(`token=${tokenPayload}`); 
        }
        if (queryParams.length > 0) {
            url += `?${queryParams.join('&')}`;
        }

        return axios.get(url ,{...headers}).then(resp => resp.data);
    }

    
    static CheckEmail = (email) => {
        const url = `${AppConfig.ApiBaseUrl}${Constants.ApiUrl.case.checkEmail}?email=${email}`;
        console.log(url)
        return axios.get(url ,{...headers}).then(resp => resp.data);
    }


    static GenerateUintarray = (payloadFiles) => {
        const url = `${AppConfig.ApiBaseUrl}${Constants.ApiUrl.generateuintArray}`;
        return axios.post(url, payloadFiles,{ ...headers})
    }

    static updateNta = (payloadData) => {
        const url = `${AppConfig.ApiBaseUrl}${Constants.ApiUrl.update}`;
        return axios.put(url, payloadData ,{...headers})
    }

    static GetMedicationMasterData = () => {
        const url = `${AppConfig.ApiBaseUrl}${Constants.ApiUrl.getMasterMedicationData}`;
        return axios.get(url ,{...headers}).then(resp => resp.data);
    }
    static GetNTAICD_10_MasterData = () => {
        const url = `${AppConfig.ApiBaseUrl}${Constants.ApiUrl.getMasterNtaICD10Data}`;
        return axios.get(url ,{...headers}).then(resp => resp.data);
    }

    static GetErrorlog = (patientId) => {
        const url = `${AppConfig.ApiBaseUrl}${Constants.ApiUrl.getErrorLog}/${patientId}`
        return axios.get(url, { ...headers }).then(resp => resp.data);
    }
    static DeleteErrorlog = (payloadPrams) => {
        const url = `${AppConfig.ApiBaseUrl}${Constants.ApiUrl.deleteErrorlog}`
        return axios.post(url, payloadPrams, { ...headers }).then(resp => resp.data);
    }

    static getOCRPdfUrl = (fileName , userId , patientId) => {
        const url = `${AppConfig.ApiBaseUrl}${Constants.ApiUrl.getocrPdfUrl}/${fileName}/${userId}/${patientId}`;
        return axios.get(url ,{ responseType :"blob"},{...headers}).then(resp => resp.data);
    }

    static DeletePatient = (payloadPrams) => {
        const url = `${AppConfig.ApiBaseUrl}${Constants.ApiUrl.case.deletePatient}`
        return axios.post(url, payloadPrams , { ...headers }).then(resp => resp.data);
    }

    static GetSinglePatient = (patientId , token) => {
        let url = `${AppConfig.ApiBaseUrl}${Constants.ApiUrl.case.getPatientById}`
        const queryParams = [];
        if (patientId ) {
            queryParams.push(`patientId=${patientId}`);
        }
        if(token){
            queryParams.push(`token=${token}`);
        }
        if (queryParams.length > 0) {
            url += `?${queryParams.join('&')}`;
        }
        return axios.get(url, { ...headers }).then(resp => resp.data);
    }
}

export default PatientService;


