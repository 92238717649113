import React, { useEffect, useState } from "react";
import { PatientService } from "../../api-services";
import { RenderIf, Dialog } from "../../components";
import AddPatientCaseForm from "./components/AddCaseForm";
import { ParseAndFormatUtcDateTime } from "../../Utils";
import ReactPagination from "../../components/common/Pagination";
import useSortableData from "../../components/customHook/SortableTable";
import io from 'socket.io-client';
import { useNavigate } from "react-router";
import ColorService from "../../styleService/ColorService";
import TextPositonService from "../../styleService/TextPositionService";
import LoggedInUserService from '../../services/AuthService'
import { useLocation, useOutletContext } from "react-router-dom";
import EditIcon from "../../assets/images/edit.svg"
import SearchbOX from "../../components/common/SearchBox";
import moment from "moment";
import { BsCheck2Circle } from 'react-icons/bs'
import { AiOutlineCloseCircle } from "react-icons/ai";
import DeleteIcon from '@mui/icons-material/Delete';
import Loader from "../users/components/CircularLoader";
import Modal from '@mui/material/Modal';
import { Container, IconButton, Typography } from "@mui/material";
import DialogBox from "../../components/common/DialogBox";
import DisclamerDialog from "../../components/common/DisclamerDialogBox";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Skeleton } from "@mui/material";
const PatientList = ({ switchBuilding = false, children, tokenPayload }) => {
   const [isAddCaseFromOpen, setAddCaseFromOpen] = useState(false);
   const [casesList, setCasesList] = useState([]);
   const location = useLocation();
   const context = useOutletContext();
   const {selectedFacilityToken, selectedFacilityData} = context || {};
   const { items, requestSort, sortConfig } = useSortableData(casesList, { direction: "sorting_asc", key: "_id" });
   const [currentPage, setCurrentPage] = useState(1);
   const [totalCount, settotalCount] = useState("");
   const [totalPages, settotalPages] = useState(0);
   const [searchText, setsearchText] = useState("")
   const [showEntries, setshowEntries] = useState(10)
   const [demandLetterProgress, setDemandLetterPorgress] = useState('');
   const navigate = useNavigate();
   const [chatGptResponse, setChatGptResponse] = useState([])
   const [isModalOpen, setIsModalOpen] = useState(false);
   const [errorResponseMsg, seterrorResponseMsg] = useState('')
   const [formData, setformData] = useState({})
   const [isSubmittingForm, setisSubmittingForm] = useState(false);
   const [patientSearchList, setpatientSearchList] = useState(null)
   const [patientExit, setpatientExit] = useState({})
   const [loading, setLoading] = useState(false);
   const [errorLogData, setErrorLogData] = useState(null);
   const [patientfileInfo, setpatientfileInfo] = useState({
      id: null,
      fileName: null,
      OCRfile: null

   });
   const [confirmDelete, setconfirmDelete] = useState(false)
   const [checkDisclamer, setcheckDisclamer] = useState({
      show: false,
      id: ""
   })
   const [switchFacility, setSwitchFacility] = useState(false)

   useEffect(() => {
      getPatient();
      if(selectedFacilityToken) {
         setSwitchFacility(true)
      } else {
         setSwitchFacility(false)
      }
    }, [selectedFacilityToken]);

   useEffect(() => {
      const debounceSearchTimeOut = setTimeout(() => {
         getPatient();
      }, 500)
      return () => {
         clearTimeout(debounceSearchTimeOut)
      }

   }, [currentPage, searchText, showEntries, location.search, tokenPayload])

   useEffect(() => {
      if (switchBuilding)
         return;
      // eslint-disable-next-line react-hooks/exhaustive-deps
      const tdata = LoggedInUserService.GetLoggedInUserData();
      const socket = io(process.env.REACT_APP_API_SOCKET_URL, {
         query: {
            id: tdata?.id
         },
         reconnection: true
      });
      if (socket) {
         socket.on('connect', (data) => {
            console.log('Connected to Socket.IO server.');
         });

         socket?.on('hpReport-Progress', (data) => {
            setCasesList((prev) => {
               let arrayCase = [...prev]
               let findCase = arrayCase?.findIndex((item) => item?._id === data?._id)
               if (findCase !== -1 && data) {
                  arrayCase[findCase] = data
               }
               return arrayCase
            })
         })


         socket?.on('hpMedicationReport-Progress', (data) => {
            setCasesList((prev) => {
               let arrayCase = [...prev]
               let findCase = arrayCase?.findIndex((item) => item?._id === data?._id)
               if (findCase !== -1 && data) {
                  arrayCase[findCase] = data
               }
               return arrayCase
            })
         })


         socket?.on('IsReportDiagnosisSuccess', (data) => {
            setCasesList((prev) => {
               let arrayCase = [...prev]
               let findCase = arrayCase?.findIndex((item) => item?._id === data?._id)
               if (findCase !== -1 && data) {
                  arrayCase[findCase] = data
               }
               return arrayCase
            })
         })

         socket?.on('hpnursingComponent_Progress', (data) => {
            setCasesList((prev) => {
               let arrayCase = [...prev]
               let findCase = arrayCase?.findIndex((item) => item?._id === data?._id)
               if (findCase !== -1 && data) {
                  arrayCase[findCase] = data
               }
               return arrayCase
            })
         })
      }

   }, [])


   const getPatient = () => {
      if (isAddCaseFromOpen && searchText?.length > 0) {
         setLoading(true)
      }
      else {
         setLoading(true);
      }
      PatientService.GetPatient(currentPage, showEntries, searchText, isAddCaseFromOpen && searchText?.length > 0 ? "distinctSearch" : "", selectedFacilityToken || tokenPayload).then(resp => {
         if (isAddCaseFromOpen && searchText?.length > 0) {
            setpatientSearchList(resp.data.reverse());
            setLoading(false);
         }
         else {

            setCasesList(resp.data.reverse());
            settotalPages(resp?.totalPages);
            settotalCount(resp?.totalCount);
            setLoading(false);
         }
      }).catch((err) => {
         if (err?.response?.status === 401) {
            localStorage.clear();
            navigate('/account/login')

         }
      })

   }

   const createPatient = async (formPayload) => {
      let hpFileReport = [...formPayload.hpReport]
      const formdata = new FormData();
      formdata.append("firstName", formPayload?.firstName)
      formdata.append("lastName", formPayload?.lastName)
      formdata.append("email", formPayload?.email)
      formdata.append("dob", formPayload?.dob)
      formdata.append("fileName", formPayload.hpReport?.[0]?.name ? formPayload.hpReport?.[0]?.name : "")
      formdata.append("gender", formPayload?.gender)
      formdata.append("id", formPayload?.id)
      if (selectedFacilityData?.contactEmail) {
         formdata.append("subDomainName", selectedFacilityData.subdomain);
         formdata.append("dataBaseName", selectedFacilityData.dataBaseName);
         formdata.append("contactEmail", selectedFacilityData.contactEmail);
     }
      if (Array.isArray(hpFileReport)) {
         hpFileReport.forEach((file, index) => {
            formdata.append("hpReport", file)
         });
      }
      navigate('/patient/list', { state: { formData } })
      setAddCaseFromOpen(false)
      setIsModalOpen(false)
      await PatientService.AddPatient(formdata).then((response) => {
         setisSubmittingForm(false);
         setIsModalOpen(false);
         setTimeout(() => {
            getPatient();
         }, 4000)
         setAddCaseFromOpen(false)
      }).catch((err) => { setisSubmittingForm(false); })

   }

   const addCases = async (formPayload, setErrors) => {
      setisSubmittingForm(true);
      let hpFileReport = [...formPayload.hpReport]
      const formdata = new FormData();
      formdata.append("firstName", formPayload?.firstName)
      formdata.append("lastName", formPayload?.lastName)
      formdata.append("admissiondate", formPayload?.admissiondate)
      formdata.append("dob", formPayload?.dob)
      if (selectedFacilityData?.contactEmail) {
         formdata.append("subDomainName", selectedFacilityData.subdomain);
         formdata.append("dataBaseName", selectedFacilityData.dataBaseName);
         formdata.append("contactEmail", selectedFacilityData.contactEmail);
     }
      // formdata.append("gender", formPayload?.gender)
      let fileNameArray = []
      formdata.append("id", formPayload?.id)
      if (Array.isArray(hpFileReport)) {
         hpFileReport.forEach((file, index) => {
            formdata.append("hpReport", file)
            fileNameArray.push(file?.name)
         });
      }
      formdata.append("fileName", JSON.stringify(fileNameArray))

      if (formPayload?.id !== undefined && formPayload?.id !== null && formPayload?.id !== "") {
         await PatientService.AddPatient(formdata).then((response) => {
            setTimeout(() => {
               getPatient();
            }, 4000)
            setAddCaseFromOpen(false);
            setisSubmittingForm(false);
            setpatientSearchList([])
            setpatientExit({})
         }).catch((err) => { setisSubmittingForm(false); })
      }
      else {
         await PatientService.AddPatient(formdata).then((response) => {
            setTimeout(() => {
               getPatient();
            }, 4000)
            setAddCaseFromOpen(false);
            setisSubmittingForm(false);
            setpatientSearchList([])
            setpatientExit({})
         }).catch((err) => { setisSubmittingForm(false); })
         // PatientService.CheckEmail(formPayload?.email).then(async (res) => {
         //    await createPatient(formPayload)
         // }).catch((err) => {
         //    //setErrors({ ...err?.response?.data?.message })
         //    seterrorResponseMsg(err?.response?.data?.message)
         //    setAddCaseFromOpen(true)
         //    setIsModalOpen(true)
         //    setformData({ ...formPayload })
         //    setisSubmittingForm(false);
         // })
      }

   }
   const PdfPreview = (id) => {
      if (switchFacility || switchBuilding) {
         window.open(`/admin/pdf-Preview?patientId=${(btoa(id))}&token=${btoa(selectedFacilityToken || tokenPayload)}`)
      }
      else {
         window.open(`/patient/pdf-Preview?patientId=${(btoa(id))}`)
      }
      setcheckDisclamer((prev) => ({ ...prev, show: false }))
      //  navigate('/patient/pdf-Preview', { state: gptResponse })
   }

   const handlePageChange = (event, pageNumber) => {
      setCurrentPage(pageNumber);
   };
   const getClassNamesFor = (name) => {
      if (!sortConfig) {
         return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
   };



   const addCaseFrom = () => {
      const newPath = `${location.pathname}?add`;
      navigate(newPath);
      setDemandLetterPorgress('')
      setAddCaseFromOpen(true)
   }

   const openDialogBox = async (id, fileName, OCRfile) => {
      setIsModalOpen(true)
      PatientService.GetErrorlog(id).then((res) => {
         let params = { id, fileName, OCRfile }
         setpatientfileInfo(params)
         setErrorLogData(res.data?.[0]);
      }).catch(err => {

      })

   }
   const deleteErrorLogPatient = async () => {
      try {
         const { id, fileName, OCRfile } = patientfileInfo
         let payloadPrams = { id, fileName, OCRfile }

         await PatientService.DeleteErrorlog(payloadPrams)
            .then((resp) => {
               setIsModalOpen(false)
               setLoading(false);
               setCasesList((prev) => {
                  let array = [...prev]
                  return array?.filter((item) => item?._id.toString() !== id?.toString())
               })
               toast.success(resp?.data?.message)
               setconfirmDelete(false)
               setpatientfileInfo({
                  id: null,
                  fileName: null,
                  OCRfile: null
               })
            })
            .catch((error) => {
               setLoading(false);
               toast.success(error?.data?.message)
               console.log(error)
            }
            )
      }
      catch (err) {

      }
   }

   // const handleStringCheck = (jsonString) => {
   //    try {
   //       // Parse the JSON string
   //       const parsedObject = JSON.parse(jsonString);

   //       // Check if the 'data' property is an array
   //       if (Array.isArray(parsedObject)) {
   //          return parsedObject
   //       } else {
   //          return []
   //       }
   //    } catch (error) {
   //       console.error("Error parsing JSON:", error);
   //    }
   // }



   const handledeletePatient = async () => {
      const { id, fileName, OCRfile } = patientfileInfo
      const payloadPrams = {
         id,
         fileName,
         OCRfile,
         ...(selectedFacilityData && {
            subDomainName: selectedFacilityData.subdomain,
            dataBaseName: selectedFacilityData.dataBaseName,
         }),
      };
      try {

         let resp = await PatientService.DeletePatient(payloadPrams);
         setLoading(false);
         toast.success(resp?.data?.message)
         setCasesList((prev) => {
            let array = [...prev]
            return array?.filter((item) => item?._id.toString() !== id?.toString())
         })
         setpatientfileInfo({
            id: null,
            fileName: null,
            OCRfile: null
         })
         setconfirmDelete(false)
      }
      catch (err) {
         setLoading(false);
         toast.success(err?.data?.message)
      }
   }

   const style = {
      paddingRight: "30px",
      paddingLeft: "30px",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "30%",
      height: "30%",
      bgcolor: "background.paper",
      border: "2px solid #000",
      boxShadow: 24,
      overflow: "auto",
      p: 4,
   };

   // if (loading && !isAddCaseFromOpen)
   //    return <Loader />
   return <>
      <ToastContainer />
      <div className="listing">

         {!switchBuilding ? <div class={`title d-flex ${!isAddCaseFromOpen ? 'justify-content-between' : 'justify-content-end'} align-items-center`}>

            {!isAddCaseFromOpen && <span>List of All Patient</span>}
            {
               !isAddCaseFromOpen
                  ?
                  <button class="btn btn-viewPdf" onClick={() => addCaseFrom()} ><i class="fa-solid fa-plus me-2"></i> Add Patient</button>
                  :
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "flex-start", gap: "4" }}>
                     <SearchbOX setsearchText={setsearchText} searchText={searchText} searcData={patientSearchList} setpatientSearchList={setpatientSearchList} setpatientExit={setpatientExit} loading={loading} />
                     <button class="btn btn-viewPdf" onClick={() => { setAddCaseFromOpen(false); setpatientExit({}); setpatientSearchList([]); setsearchText('') }}><i class="fa-solid fa-arrow-left me-2"></i>Back to Patient</button>
                  </div>

            }

         </div>
            :
            <>
               <Container
                  sx={{
                     display: 'flex',
                     justifyContent: 'center',
                     alignItems: 'center',
                     height: '30vh',
                     flexDirection: 'column',
                     textAlign: 'center',
                     backgroundColor: '#f5f5f5',
                     marginBottom: "20px"
                  }}
               >
                  {children}
                  <Typography variant="h4" component="div" gutterBottom>
                     Please select facility to view patient
                  </Typography>
               </Container>

            </>

         }


         <RenderIf shouldRender={isAddCaseFromOpen}>
            <AddPatientCaseForm isSubmittingForm={isSubmittingForm} addCases={addCases} onCancel={() => { setAddCaseFromOpen(false); setpatientExit({}); setpatientSearchList([]) }} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} errorResponseMsg={errorResponseMsg} createPatient={() => createPatient(formData)} patientExit={patientExit} />
         </RenderIf>

         {switchBuilding && tokenPayload === null
            ? <></> :
            <RenderIf shouldRender={!isAddCaseFromOpen}>
               <div id="myTable_wrapper" class="dataTables_wrapper no-footer">
                  <div class="dataTables_length" id="myTable_length">
                     <label>
                        Show
                        <select value={showEntries} onChange={(e) => setshowEntries(parseInt(e.target.value))} name="myTable_length" aria-controls="myTable" class="">
                           <option value="10">10</option>
                           <option value="25">25</option>
                           <option value="50">50</option>
                           <option value="100">100</option>
                        </select>
                        entries
                     </label>
                  </div>
                  <div id="myTable_filter" class="dataTables_filter"><label>Search:<input value={searchText} onChange={(e) => setsearchText(e.target.value)} type="search" class="" placeholder="" aria-controls="myTable" /></label></div>
                  <div className="patient_list">
                     <table id="myTable" class="display dataTable no-footer" aria-describedby="myTable_info">
                        <thead>
                           <tr>
                              <th style={{ width: "204.583px" }}>Admission Date </th>
                              <th onClick={() => requestSort(`firstName`)} className={`sorting ${getClassNamesFor("detailsInput.liability.caseName")}`} tabindex="0" aria-controls="myTable" rowspan="1" colspan="1" aria-label="Title: activate to sort column ascending" style={{ width: "420.417px" }}>Name
                              </th>
                              {/* <th onClick={() => requestSort(`email`)} className={`sorting ${getClassNamesFor("detailsInput.liability.caseName")}`} tabindex="0" aria-controls="myTable" rowspan="1" colspan="1" aria-label="Title: activate to sort column ascending" style={{ width: "420.417px" }}>Email
                    </th>
                    <th style={{ width: "204.583px" }}>Gender</th> */}
                              <th onClick={() => requestSort('dob')} className={`sorting  ${getClassNamesFor("createdOn")}`} tabindex="0" aria-controls="myTable" rowspan="1" colspan="1" aria-label="Case Added Date: activate to sort column ascending" style={{ width: "204.583px" }}>Date of Birth</th>
                              <th style={{ width: "204.583px" }}>Uploaded documents</th>
                              <th style={{ width: "204.583px" }}>Medical Diagnosis</th>
                              <th style={{ width: "204.583px" }}>Medication</th>
                              <th style={{ width: "204.583px" }}>Nursing Component</th>
                              <th style={{ width: "204.583px" }}>NTACal </th>
                              <th style={{ width: "204.583px" }}> </th>
                              <th style={{ width: "204.583px" }}> </th>

                           </tr>
                        </thead>
                        <tbody>
                           {
                              loading ?
                                 (
                                    Array.from(new Array(4)).map((_, index) => (
                                       <tr key={index}>
                                          <td><Skeleton variant="rectangular" height={40} /></td>
                                          <td><Skeleton variant="rectangular" height={40} /></td>
                                          <td><Skeleton variant="rectangular" height={40} /></td>
                                          <td><Skeleton variant="rectangular" height={40} /></td>
                                          <td><Skeleton variant="rectangular" height={40} /></td>
                                          <td><Skeleton variant="rectangular" height={40} /></td>
                                          <td><Skeleton variant="rectangular" height={40} /></td>
                                          <td><Skeleton variant="rectangular" height={40} /></td>
                                       </tr>
                                    ))
                                 )

                                 : items?.map((x, index) => {

                                    return <tr class={index % 2 === 0 ? "odd" : 'even'}>
                                       <td>{moment(x?.admissiondate)?.format('DD-MM-YYYY')}</td>
                                       <td class="user-name sorting_1">
                                          {/* <img src="images/user.png" class="img-fluid me-2" /> */}
                                          <span>{x?.firstName} {x?.lastName}</span>
                                       </td>
                                       {/* <td>{x?.email}</td>
                       <td>{x?.gender}</td> */}
                                       <td>{ParseAndFormatUtcDateTime(x?.dob)}</td>
                                       <td>
                                          {
                                             x?.fileName?.length < 1 ? <i onClick={() => { navigate('/patient/list?edit', { state: x }); setAddCaseFromOpen(true) }} class="bi bi-pencil-square text-center"><img src={EditIcon} alt="" /></i>
                                                :
                                                <>
                                                   {
                                                      Array.isArray(x?.fileName) &&
                                                      x?.fileName?.map((item, i) => (
                                                         <>
                                                            <a
                                                               href={item?.filePath}
                                                               target="_blank"
                                                               rel="noopener noreferrer"
                                                            >
                                                               {item?.fileName?.length > 15 ? <span> {`${item?.fileName?.substring(0, 11)}...`}</span> : <span>{item?.fileName}</span>}
                                                            </a>
                                                            <br />
                                                         </>
                                                      ))
                                                   }

                                                </>


                                          }

                                       </td>
                                       {
                                          x?.resultProgress?.hpReport_Progress === "" && x?.resultProgress?.hpReport_Progress === "" && x?.resultProgress?.hpnursingComponent_Progress === ""
                                             ?
                                             <td colSpan={3}>
                                                <div style={{
                                                   width: 'auto',
                                                   height: '50px',
                                                   display: 'flex',
                                                   justifyContent: 'center',
                                                   alignItems: 'center',
                                                   backgroundColor: '#F7F7F7',  // Set your desired background color
                                                   border: '3px solid #86BD3B',
                                                   color: '#666666',
                                                   fontFamily: 'sans-serif',
                                                   fontSize: '18px',
                                                   borderRadius: '5px',
                                                   textAlign: 'center',
                                                   paddingTop: '10px'
                                                }}>
                                                   Please wait while uploading is in process...
                                                </div>
                                             </td>
                                             :
                                             <>
                                                <td>
                                                   <RenderIf shouldRender={x?.resultProgress?.hpReport_Progress}>
                                                      <div style={{ width: '9rem', height: 32, position: "relative", border: '2px solid black', color: 'black', borderRadius: '10px', textAlign: 'center', }}>
                                                         <span style={{

                                                            position: 'absolute', marginLeft: x?.resultProgress?.hpReport_Progress?.length >= 2 ? TextPositonService.patientReportProgress(x?.resultProgress?.hpReport_Progress?.split(' ')) : '-20px',
                                                            color: ColorService.patientReportProgress(Array.isArray(x?.resultProgress?.hpReport_Progress) ? x?.resultProgress?.hpReport_Progress.split(' ') : x?.resultProgress?.hpReport_Progress)
                                                         }}>
                                                            {x?.resultProgress?.hpReport_Progress}{isNaN(x?.resultProgress?.hpReport_Progress) ? "" : "%"}</span>
                                                         <div style={{
                                                            width: x?.resultProgress?.hpReport_Progress === "Successful" ? '100%' : x?.resultProgress?.hpReport_Progress + '%',
                                                            height: '1.9rem',
                                                            backgroundColor: '#1C47D1',
                                                            borderRadius: '7px',
                                                            color: 'white',
                                                            textAlign: 'center',
                                                            fontSize: 12,
                                                            // paddingTop: 8
                                                         }} ></div>
                                                      </div>
                                                   </RenderIf>
                                                </td>
                                                <td>
                                                   <RenderIf shouldRender={x?.resultProgress?.hpMedicationReport_Progress}>
                                                      <div style={{ width: '9rem', height: 32, position: "relative", border: '2px solid black', color: 'black', borderRadius: '10px', textAlign: 'center', }}>
                                                         <span style={{

                                                            position: 'absolute', marginLeft: x?.resultProgress?.hpMedicationReport_Progress?.length >= 2 ? TextPositonService.medicationReportProgress(x?.resultProgress?.hpMedicationReport_Progress?.split(' ')) : '-20px',
                                                            color: ColorService.medicationReportProgress(Array.isArray(x?.resultProgress?.hpMedicationReport_Progress) ? x?.resultProgress?.hpMedicationReport_Progress.split(' ') : x?.resultProgress?.hpMedicationReport_Progress)
                                                         }}>
                                                            {x?.resultProgress?.hpMedicationReport_Progress}{isNaN(x?.resultProgress?.hpMedicationReport_Progress) ? "" : "%"}</span>
                                                         <div style={{
                                                            width: x?.resultProgress?.hpMedicationReport_Progress === "Successful" ? '100%' : x?.resultProgress?.hpMedicationReport_Progress + '%',
                                                            height: '1.9rem',
                                                            backgroundColor: '#1C47D1',
                                                            borderRadius: '7px',
                                                            color: 'white',
                                                            textAlign: 'center',
                                                            fontSize: 12,
                                                            // paddingTop: 8
                                                         }} ></div>
                                                      </div>
                                                   </RenderIf>
                                                </td>
                                                <td>
                                                   <RenderIf shouldRender={x?.resultProgress?.hpnursingComponent_Progress}>
                                                      <div style={{ width: '9rem', height: 32, position: "relative", border: '2px solid black', color: 'black', borderRadius: '10px', textAlign: 'center', }}>
                                                         <span style={{

                                                            position: 'absolute', marginLeft: x?.resultProgress?.hpnursingComponent_Progress?.length >= 2 ? TextPositonService.nursingReportProgress(x?.resultProgress?.hpnursingComponent_Progress?.split(' ')) : '-20px',
                                                            color: ColorService.nursingReportProgress(Array.isArray(x?.resultProgress?.hpnursingComponent_Progress) ? x?.resultProgress?.hpnursingComponent_Progress.split(' ') : x?.resultProgress?.hpnursingComponent_Progress)
                                                         }}>
                                                            {x?.resultProgress?.hpnursingComponent_Progress}{isNaN(x?.resultProgress?.hpnursingComponent_Progress) ? "" : "%"}</span>
                                                         <div style={{
                                                            width: x?.resultProgress?.hpnursingComponent_Progress === "Successful" ? '100%' : x?.resultProgress?.hpnursingComponent_Progress + '%',
                                                            height: '1.9rem',
                                                            backgroundColor: '#1C47D1',
                                                            borderRadius: '7px',
                                                            color: 'white',
                                                            textAlign: 'center',
                                                            fontSize: 12,
                                                            // paddingTop: 8
                                                         }} ></div>
                                                      </div>
                                                   </RenderIf>
                                                </td>
                                             </>
                                       }
                                       <td>
                                          <RenderIf shouldRender={x?.resultProgress?.hpReport_Progress === "Successful" && x?.resultProgress?.hpMedicationReport_Progress === "Successful" && x?.resultProgress?.hpnursingComponent_Progress === "Successful"}>
                                             {/* <button class="btn btn-viewPdf1" onClick={x?.resultProgress?.hpReport_Progress === "Successful" && x?.resultProgress?.hpMedicationReport_Progress === "Successful" && x?.resultProgress?.hpnursingComponent_Progress === "Successful" ? () => { PdfPreview(x._id, index, x?.resultProgress?.hpReport_Progress) }
                                   : () => { PdfPreview(x._id, index, x?.resultProgress?.hpReport_Progress) }}>View</button> */}
                                             <button class="btn btn-viewPdf1" onClick={() => setcheckDisclamer({
                                                show: true,
                                                id: x?._id
                                             })}>View</button>

                                          </RenderIf>
                                       </td>
                                       {!switchBuilding &&
                                          <>
                                             <td>
                                                <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                   <RenderIf shouldRender={x?.isCaseGeneratedSccessfuly != null && !x?.isCaseGeneratedSccessfuly}>
                                                      <AiOutlineCloseCircle size={40} color="red" onClick={() => openDialogBox(x._id, x?.fileName, x?.OCRfile)} />
                                                   </RenderIf>
                                                   <RenderIf shouldRender={x?.isCaseGeneratedSccessfuly !== null && x?.isCaseGeneratedSccessfuly && x?.resultProgress?.hpReport_Progress === "Successful" && x?.resultProgress?.hpMedicationReport_Progress === "Successful"}>
                                                      <BsCheck2Circle size={40} color="green" />
                                                   </RenderIf>
                                                </span>
                                             </td>
                                             <td>
                                                <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                   <RenderIf shouldRender={x?.isCaseGeneratedSccessfuly !== null && x?.isCaseGeneratedSccessfuly && x?.resultProgress?.hpReport_Progress === "Successful" && x?.resultProgress?.hpMedicationReport_Progress === "Successful"}>
                                                      <IconButton sx={{ color: "#C53E4E" }} onClick={() => {
                                                         setconfirmDelete(true);
                                                         let params = {
                                                            id: x?._id
                                                            , fileName: x?.fileName
                                                            , OCRfile: x?.OCRfile
                                                         }
                                                         setpatientfileInfo({ ...params })

                                                      }}  >
                                                         <DeleteIcon />
                                                      </IconButton>
                                                   </RenderIf>
                                                </span>
                                             </td>
                                          </>
                                       }
                                    </tr>
                                 })}
                        </tbody>
                     </table>
                  </div>

                  <div class="dataTables_info" id="myTable_info" role="status" aria-live="polite">Showing 1 to {showEntries} of {totalCount} entries</div>

                  {totalPages > 1 && <div class="dataTables_paginate paging_simple_numbers" id="myTable_paginate">
                     <ReactPagination
                        activePage={currentPage}
                        totalItemCount={totalPages}
                        onChange={handlePageChange}
                     />
                  </div>}
                  {/* <div class="dataTables_paginate paging_simple_numbers" id="myTable_paginate"><a class="paginate_button previous disabled" aria-controls="myTable" aria-disabled="true" aria-role="link" data-dt-idx="previous" tabindex="-1" id="myTable_previous">Previous</a><span><a class="paginate_button current" aria-controls="myTable" aria-role="link" aria-current="page" data-dt-idx="0" tabindex="0">1</a></span><a class="paginate_button next disabled" aria-controls="myTable" aria-disabled="true" aria-role="link" data-dt-idx="next" tabindex="-1" id="myTable_next">Next</a></div> */}
               </div>
            </RenderIf >
         }


         <DialogBox open={confirmDelete} handleSubmit={() => handledeletePatient()} handleClose={() => setconfirmDelete(false)} title={'Are you sure want to delete?'} />
         <DisclamerDialog open={checkDisclamer.show} handleClose={() => setcheckDisclamer((prev) => ({ ...prev, show: false }))} title={'Purpose of this report is to help identify all diagnoses, conditions and patient complexity.  Diagnoses must be verified and/or queried with MD/NP.'} handleAgreeOnclick={() => PdfPreview(checkDisclamer?.id)} />
         <Modal
            open={isModalOpen}
            onClose={() => setIsModalOpen(!isModalOpen)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <div className="d-flex justify-content-center align-items-center h-100">
               <div style={style} className="card">
                  <div className="card-body">
                     <h5 className="card-title text-center mb-4">Are you sure you want to delete?</h5>
                     {errorLogData?.errorDescrition && (
                        <p className="text-danger text-center mb-4">Error: {errorLogData.errorDescrition}</p>
                     )}
                     <div className="d-flex justify-content-center">
                        <button
                           className="btn btn-outline-secondary me-3"
                           onClick={() => setIsModalOpen(false)}
                        >
                           No
                        </button>
                        <button className="btn btn-outline-danger" onClick={deleteErrorLogPatient}>
                           Yes
                        </button>
                     </div>
                  </div>
               </div>
            </div>
         </Modal>
      </ div >
   </>

}


export default PatientList