import React, { createElement, useEffect, useState } from 'react';
import { Field, Formik, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { TextInput, FileUpload, DatePicker, Dropdown } from '../../../components';
import Constants from '../../../Constants';
import { ParseAndFormatUtcDate } from '../../../Utils';
import { Box, IconButton, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';



const AddPatientForm = ({ onSubmit, onReportUpload, onCancel, editData, patientExit, setHpFileReport, HpFileReport }) => {
  const [fileName, setFileName] = useState('');

  const onFileSelected = async (files) => {
    setFileName(files?.file[0]?.name);
    onReportUpload(files.file)
  }


  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    // email: Yup.string().required('Email is required').email('Please enter a valid email'),
    dob: Yup.string().required('Date of birth is required'),
    admissiondate: Yup.string().required('Admission Date is required'),
  })


  return (
    <Formik
      initialValues={{
        firstName: (editData?.firstName || patientExit?.firstName) ? (editData?.firstName || patientExit?.firstName) : '',
        lastName: (editData?.lastName || patientExit?.lastName) ? (editData?.lastName || patientExit?.lastName) : '',
        // email:  editData?.email ? editData?.email : '',
        // gender: editData?.gender ? editData?.gender : '',
        dob: (editData?.dob || patientExit?.dob) ? (editData?.dob || patientExit?.dob) : "",
        admissiondate: editData?.admissiondate ? editData?.admissiondate : ""
      }}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={(values, { setErrors }) => {
        values.id = editData?._id
        onSubmit(values, setErrors);
      }}
    >
      {() => (
        <Form>
          <div className="p-4">
            <h2 className="title">Add Patient
            </h2>
            <div className="row pt-3">
              <div className="col-6">
                <div className="form-group">
                  <Field name="firstName" placeholder="Enter First Name" label="First Name" component={TextInput} />
                </div>
              </div>

              <div className="col-6">
                <div className="form-group">
                  <Field name="lastName" placeholder="Enter Last Name" label="Last Name" component={TextInput} />
                </div>
              </div>
              {/* 
                <div className="col-6">
                  <div className="form-group">
                    <Field name="email" placeholder="Enter Email" label="Email" component={TextInput} />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <Field
                      name="gender"
                      label="Gender"
                      component={Dropdown}
                      defaultOption="Select Gender"
                      options={Constants.Dropdowns.Gender}
                    />
                  </div>
                </div> */}
              <div className="col-md-6">
                <div className="form-group">
                  <Field maxDate={ParseAndFormatUtcDate(new Date())} name="dob" label="Date of birth" component={DatePicker}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <Field name="admissiondate" label="Admission Date" component={DatePicker}
                  />
                </div>
                <div className="form-group mb-0">
                  <label for="exampleFormControlTextarea1" className="form-label">Attach Document (If Available)
                  </label>
                  <div className="">
                    {/* <div className="text text-center"> */}
                      <Field name="accidentScenes" component={FileUpload} multiplefileUpload={true} onFileSelected={onFileSelected} />
                      {
                  HpFileReport?.length > 0 &&
                  <Box
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Box className="file_list_wrraper" >
                      {
                        HpFileReport?.map((file, i) => (
                          <div key={i} className='file_list'>
                            <div style={{ display: 'flex', alignItems: "center",justifyContent :"space-between", }}>
                              <Typography >{file?.name}</Typography>
                              <IconButton onClick={(e) => {
                                e.preventDefault();
                                setHpFileReport((prev) => {
                                  let fileArray = [...prev]
                                  fileArray.splice(i, 1)
                                  return fileArray
                                })
                              }}>
                                <DeleteIcon
                                />
                              </IconButton>
                            </div>

                          </div>
                        ))
                      }
                    </Box>
                  </Box>
                }
                    </div>
                  </div>
                {/* </div> */}
              </div>
            </div>
          </div>
          <div className="btns text-center">
            <button className="btn-theme" type="submit">{editData?._id ? "Update" : "Save"}</button>
            <button className="btn-secondary form-buttons-margin" onClick={(e) => { e.preventDefault(); onCancel() }} >Cancel</button>
          </div>
        </Form>
      )
      }
    </Formik>

  )


}

export default AddPatientForm;