import React from 'react';
import '././dashboard.css';
import AuthService from '../../services/AuthService';
const Dashboard = () => {
    const userInfo = AuthService.GetLoggedInUserData();
    return (
        <div className="dashboard-container">
            <div className="well">
                <h4>Dashboard</h4>
                <p>{userInfo?.firstName}  {userInfo?.lastName}</p>
            </div>
            <div className="row">
                <div className="col-md-4">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Number of patients</h5>
                            <p className="card-text">50</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">H&Ps submitted</h5>
                            <p className="card-text">40</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Total number of NTAs</h5>
                            <p className="card-text">10</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;