import React, { useState } from 'react';

import { Dialog } from "../../../components";
import AddEditUserForm from './AddEditUserForm';
import { ClipLoader } from 'react-spinners';

const AddEditUserDialog = ({ isDialogOpened, onToggleDialog, onSubmit, isSubmittingForm ,editUserInfo , seteditUserInfo }) => {
    if(!isDialogOpened)
        seteditUserInfo()

    return <Dialog
        isModalOpen={isDialogOpened}
        onToggleDialog={(isDialogOpen) =>onToggleDialog(isDialogOpen)}
        mode="half"
        isCloseIconHidden={false}
        additionalStyle={{position:'relative'}}
    >
        <ClipLoader
          color={'#18479A'}
          loading={isSubmittingForm}
          size={30}
          aria-label="Loading Spinner"
          data-testid="loader"
          cssOverride={{position:'absolute', left: '50%', top: '50%'}}
        />
        <AddEditUserForm
            isSubmittingForm={isSubmittingForm}
            onSubmit={onSubmit}
            onCancel={() => onToggleDialog(false)}
            editUserInfo={editUserInfo}
        />

    </Dialog>

}

export default AddEditUserDialog;