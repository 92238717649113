import React from "react";
import { Outlet, useLocation } from "react-router-dom";

const Account = (props) => {
    const location = useLocation();

    return <>
        <section className="login p-0">
            <div className="row m-0 admin_layout">
                <div className="col-lg-6 col-md-12 order-md">
                    <div className="content">
                        <Outlet />
                    </div>
                </div>
                {/* {
                    !location.pathname.includes('admin') && <div className="col-lg-6 col-md-12">
                        <div className="right">
                            <h2 className="text">Daily Asses can help<br /> your facility with PDPM</h2>
                        </div>
                    </div>
                } */}

            </div>
        </section>
    </>
}

export default Account;