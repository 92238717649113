import { Navigate, Outlet, useLocation } from "react-router-dom";
import LoggedInUserService from '../services/AuthService'
import Constants from '../Constants';

const ProtectedRoute = ({ redirectPath = '/account/login' }) => {
    const { hostname } = window.location;
    const location = useLocation(); 
    const tdata = LoggedInUserService.GetLoggedInUserData();
    if (tdata === undefined || (!Constants.getAuthtoken()?.isToken && tdata?.role === 0)) {
        if(getSubdomain(hostname) === "master"){
            return <Navigate to={'/account/admin/login'}
            replace
            state={{ from: location }} />;
        }
        else {
            return <Navigate to={redirectPath}
            replace
            state={{ from: location }} />;
        }
       
    }
    if(tdata!==undefined &&tdata?.role === 0 ){
    return <Outlet />;
    }
    else if(tdata!==undefined &&tdata?.role === 1) {
       return <Navigate to={'/admin/list'}
            replace
            state={{ from: location }} />;
    }
};
export default ProtectedRoute;

function getSubdomain(hostname) {
    try {
      const parts = hostname.split('.');
      console.log(parts.length)
      if (parts?.length > 1) {
        return parts[0];
      }
      return '';
    } catch (error) {
      console.error('Error extracting subdomain:', error);
      return '';
    }
  }