import PatientService from "./PatientService";
import UserService from "./UserService";
import AuthService from './AuthService'
import Constants from "../Constants";
import PdfConverterService from './PdfConverterService'
import SuperAdminAuthService from './SuperAdminAuthService'
import SuperAdminService from './SuperAdminService'
export { PatientService, UserService, AuthService, PdfConverterService ,SuperAdminAuthService ,SuperAdminService };


export const headers = Constants?.getAuthtoken()?.token != undefined && Constants?.getAuthtoken()?.token != null && {
    headers: {
        "authorization": `Bearer ${JSON.parse(Constants.decrypt(Constants?.getAuthtoken()?.token))}`
    }
}
