import { Box, IconButton, Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
export default function ModalPopup({ childern, show, handleClose }) {


    return (
        <Modal
            open={show}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className={"modal_poppup"}
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Box style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
                padding: "20px",
                position: "relative",
                maxWidth: "400px",
                width: "90%",
            }} >
                <IconButton
                    aria-label="close"
                    style={{
                        position: "absolute",
                        top: "8px",
                        right: "8px",
                    }}
                    onClick={handleClose}
                >
                    <CloseIcon />
                </IconButton>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    {childern}
                </Typography>
            </Box>
        </Modal>
    );
}
