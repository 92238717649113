import React, { useEffect, useState } from "react";
import { UserService } from "../../api-services"
import { useLocation, useNavigate } from "react-router-dom";
import ReactPagination from "../../components/common/Pagination";
import { Skeleton } from "@mui/material";
import Loader from "./components/CircularLoader";
import { ParseAndFormatUtcDateTime } from "../../Utils";
const AuditLoglList = () => {
    const [auditLog, setAuditLog] = useState([]);
    const location = useLocation();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, settotalCount] = useState("");
    const [totalPages, settotalPages] = useState(0);
    const [searchText, setsearchText] = useState("")
    const [showEntries, setshowEntries] = useState(50)
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [confirmDelete, setconfirmDelete] = useState({
        show: false,
        id: null
    })

    useEffect(() => {
        const debounceSearchTimeOut = setTimeout(() => {
            getAuditLogList();
        }, 500)
        return () => {
            clearTimeout(debounceSearchTimeOut)
        }
    }, [currentPage, searchText, showEntries, location.search])


    const getAuditLogList = async () => {
        setLoading(true)
        UserService.GetAuditLog(currentPage, showEntries, searchText).then(resp => {
            setAuditLog(resp.data);
            settotalPages(resp?.totalPages);
            settotalCount(resp?.totalCount)
            setLoading(false)
        }).catch((err) => {
            setLoading(false)
            if (err?.response?.status === 401) {
                localStorage.clear();
                navigate('/account/login')

            }
        })
    }



    const handlePageChange = (event, pageNumber) => {
        setCurrentPage(pageNumber);
    };

    if (loading)
        return <Loader />
    console.log(auditLog, "audit")
    return (
        <>

            <div className="listing">

                <div class={`title d-flex ${'justify-content-start'} align-items-center`}>

                    <span>List of All Logs</span>

                </div>

                <div id="myTable_wrapper" class="dataTables_wrapper no-footer">
                    <div className="patient_list">
                        <table id="myTable" class="display dataTable no-footer" aria-describedby="myTable_info">
                            <thead>
                                <tr>
                                    <th>User Name</th>
                                    <th>URL</th>
                                    <th>Method </th>
                                    <th>Time </th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {

                                    loading ?
                                        (
                                            Array.from(new Array(4)).map((_, index) => (
                                                <tr key={index}>
                                                    <td><Skeleton variant="rectangular" height={40} /></td>
                                                    <td><Skeleton variant="rectangular" height={40} /></td>
                                                    <td><Skeleton variant="rectangular" height={40} /></td>
                                                    <td><Skeleton variant="rectangular" height={40} /></td>
                                                    <td><Skeleton variant="rectangular" height={40} /></td>
                                                    <td><Skeleton variant="rectangular" height={40} /></td>
                                                    <td><Skeleton variant="rectangular" height={40} /></td>
                                                    <td><Skeleton variant="rectangular" height={40} /></td>
                                                </tr>
                                            ))
                                        )
                                        :
                                        auditLog?.map((x, index) => {

                                            return (
                                                <tr class={index % 2 === 0 ? "odd" : 'even'}>
                                                    <td>{x?.userDetails?.firstName} {x?.userDetails?.lastName}</td>
                                                    <td>{x?.url}</td>
                                                    <td>{x?.action}</td>
                                                    <td>{ParseAndFormatUtcDateTime(x?.event_timestamp)}</td>
                                                    <td>{x?.status}</td>
                                                </tr>
                                            )
                                        })}
                            </tbody>
                        </table>
                    </div>

                    <div class="dataTables_info" id="myTable_info" role="status" aria-live="polite">Showing 1 to {showEntries} of {totalCount} entries</div>

                    {totalPages > 1 && <div class="dataTables_paginate paging_simple_numbers" id="myTable_paginate">
                        <ReactPagination
                            activePage={currentPage}
                            totalItemCount={totalPages}
                            onChange={handlePageChange}
                        />
                    </div>}
                </div>
            </div>
        </>
    )

}


export default AuditLoglList