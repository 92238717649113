class ColorService {
    static patientReportProgress = (progress) => {
        if (progress) {
            let extracted = progress[0] === "Extracted" ? progress[0] : progress
            let uploading = progress[0] === "Uploading" ? progress[0] : progress
            let generating = progress[0] === "Generating" ? progress[0] : progress
            switch (progress) {
                case generating:
                    if (progress < 50) {
                        return 'black'
                    } else if (progress > 40) {
                        return 'white'
                    } else {
                        return 'white'
                    }
                case 'Successful':
                    return 'white'
                case uploading:
                    return 'white'
                case 'Refining':
                    return 'white'
                case extracted:
                    return 'white'
                // case 'Generating Record':
                //     return 'white'
                default:
                    if (progress < 50) {
                        return 'black'
                    }
                    if (progress > 40) {
                        return 'white'
                    }
                    return 'black';
            }

        }
    }

    static medicationReportProgress = (progress) => {
        if (progress) {

            switch (progress) {
                case 'Generating':
                    return 'white'
                case 'Successful':
                    return 'white'
                case 'Extracted':
                    return 'white'
                case 50:
                    return 'white'
                case 'Sucessfull':
                    return 'white'
                default:
                    if (progress > 50) {
                        return 'white'
                    }
                    return 'black';
            }
        }
    }

    
    static nursingReportProgress = (progress) => {
        if (progress) {
            let nrusingGeneratingBar = progress[0] === "Generating" ? progress[0] : progress
            let nursingServiceBar = progress[1] === "..." ? progress[0] : progress
            switch (progress) {
                case nrusingGeneratingBar:
                    return 'white'
                case 'Successful':
                    return 'white'
                case 'Uploading':
                    return 'white'
                case 'Extracted':
                    return 'white'

                case nursingServiceBar:
                    return 'white'

                default:
                    if (progress < 50) {
                        return 'white'
                    }
                    if (progress > 50) {
                        return 'white'
                    }
            }
        }

    }
}

export default ColorService;