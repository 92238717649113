import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export default function DialogBox({ title, open, handleSubmit, handleClose }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      className="dialog-box"
      aria-labelledby="responsive-dialog-title"
      maxWidth="md" // or "lg", "xl" for larger widths
      PaperProps={{
        style: {
          borderRadius: 12,
          boxShadow: "0 0 20px rgba(0, 0, 0, 0.15)",
        },
      }}
    >
      <DialogTitle
        id="responsive-dialog-title"
        style={{
          fontWeight: 600,
          fontSize: "1.2rem",
          padding: "20px 24px 0",
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent style={{ padding: "0 24px" }}>
        <DialogContentText
          style={{
            fontWeight: 400,
            fontSize: "1rem",
            marginBottom: 24,
          }}
        >
          {/* Your dialog content goes here */}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        style={{
          padding: "16px 24px",
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant="text"
          style={{
            color: "#32CD32",
            marginRight: 16,
            transition: "all 0.3s ease",
          }}
          onMouseOver={(e) => {
            e.currentTarget.style.backgroundColor = "#32CD32";
            e.currentTarget.style.color = "#fff";
          }}
          onMouseOut={(e) => {
            e.currentTarget.style.backgroundColor = "";
            e.currentTarget.style.color = "#32CD32";
          }}
          onClick={handleClose}
          autoFocus
        >
          No
        </Button>
        <Button
          variant="outlined"
          style={{
            color: "#ff0000",
            border: "1px solid #ff0000",
            transition: "all 0.3s ease",
          }}
          onMouseOver={(e) => {
            e.currentTarget.style.backgroundColor = "#ff0000";
            e.currentTarget.style.color = "#fff";
          }}
          onMouseOut={(e) => {
            e.currentTarget.style.backgroundColor = "";
            e.currentTarget.style.color = "#ff0000";
          }}
          onClick={handleSubmit}
          autoFocus
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}