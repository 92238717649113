import Routes from "./Routes";
import SessionTimeout from "./pages/sessionTimeOut"
import "./assets/css/global-styles.css"

function App() {
  return (
    <div className="App">
      <SessionTimeout />
      <Routes />
    </div>
  );
}

export default App;
