
import { useState } from "react";
import AddHospitalDetailForm from "./AddHospitalDetail";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../../users/components/CircularLoader";

const AddHospitalCaseForm = ({ onCancel, addHospital, isSubmittingForm }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [file, setfile] = useState(null);
  const onSubmitClick = (formData, setErrors) => {
    formData.hospitalfile = file
    formData.hospitalLogo = file?.name
    addHospital(formData, setErrors)
    navigate('/admin/list')
  }

  const onReportUpload = (file) => {
    setfile(file)
  }
 
  
  return (
    <>
    {
      isSubmittingForm && <Loader/>
    }
      <AddHospitalDetailForm
        onSubmit={onSubmitClick}
        editFData = {location?.state}
        onReportUpload={onReportUpload}
        onCancel={onCancel}
      />

    </>
  )



}


export default AddHospitalCaseForm;