import { Field, Formik, Form } from 'formik'
import { useState } from 'react'
import * as Yup from 'yup'
import { TextInput } from "../../components";
import { AuthService } from "../../api-services/"
import LoggedInUserService from "../../services/AuthService";
import { Link } from "react-router-dom";
import Constants from '../../Constants';
import { useNavigate } from 'react-router-dom';
import ModalPopup from '../../components/common/Modal';
import { CreateNewPassword } from './CreateNewPassword';
import { toast } from 'react-toastify';
const companyLogo = require("../../assets/images/logo.png")


const Login = () => {
    const navigate = useNavigate();
    const [confirmAlert, setconfirmAlert] = useState({
        show: false,
        userId: null,
        subdomain: null
    })
    const [showPassword, setShowPassword] = useState(false);
    const validationSchema = Yup.object().shape({
        email: Yup.string().required('Email is required').email('Please enter a valid email'),
        password: Yup.string().required('Password is required').min(8, 'Password must be at least 8 characters long'),
    })

    function getSubdomain(hostname) {
        try {
            const parts = hostname.split('.');
            if (parts?.length > 1) {
                return parts[0]
            }
            return '';
        } catch (error) {
            console.error('Error extracting subdomain:', error);
            return '';
        }
    }

    const onSubmit = (values, setErrors) => {
        const { hostname } = window.location;
        let subdomain = getSubdomain(hostname)
        values.subdomain = subdomain
        AuthService.Login(values).then((res) => {
            const userinfoDetail = LoggedInUserService.checkloggedInUserFirstTime(res?.data?.token)
            if (userinfoDetail?.loginfirstTime !== undefined && !userinfoDetail?.loginfirstTime) {
                setconfirmAlert(prev => ({
                    ...prev,
                    show: true,
                    userId: userinfoDetail.id,
                    subdomain: subdomain
                }))
                return;
            }
            LoggedInUserService.UpdateLoggedInUserSession(res?.data?.token);
            if(userinfoDetail?.email === 'mclaren@gmail.com') {
                localStorage.setItem('isAdmin', JSON.stringify(userinfoDetail.isAdmin));
            }
            window.location.href = '/patient/list'
        }).catch(err => {
            setErrors({ ...err?.response?.data?.message })
        })
    }


    const onSubmitCreateNewPassowrd = async (payload, resetForm) => {
        const { userId, subdomain } = confirmAlert
        payload.userId = userId;
        payload.subdomain = subdomain
        AuthService.createNewPassword(payload).then((res) => {
            setconfirmAlert(prev => ({
                ...prev,
                show: false,
                userId: null,
                subdomain: null
            }))
            toast.success("New password created successfully!")
            resetForm()

        }).catch(err => {
            toast.error(err?.response?.data.message)
        })

    }


    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                {/* <h2 className="text-center">User Login</h2> */}
                                <img src={companyLogo} className="img-fluid" alt="company-logo" />
                                <Formik
                                    initialValues={{
                                        email: '',
                                        password: '',
                                    }}
                                    enableReinitialize={true}
                                    validationSchema={validationSchema}
                                    onSubmit={(values, { setErrors }) => {
                                        onSubmit(values, setErrors)
                                    }}
                                >
                                    {(formikProps) => (
                                        <Form>
                                            <div className="form-group">
                                                <Field name="email" removeMargin={true} className="form-control" shouldDollarRender={true} shouldUserRenderIcon={<> <span className="input-group-text" style={{
                                                    width: "2.5rem",
                                                    outline: "none",
                                                    height: "48px",
                                                    border: "none",
                                                    position: "absolute"
                                                }} id="basic-addon1"><i className="fa-solid fa-user"></i></span></>} placeholder="Enter Email" component={TextInput} />
                                            </div>
                                            <div className="form-group" style={{ position: 'relative' }}>
                                                <Field
                                                    name="password"
                                                    removeMargin={true}
                                                    shouldDollarRender={true}
                                                    shouldUserRenderIcon={
                                                        <>
                                                            <span
                                                                style={{
                                                                    width: "2.5rem",
                                                                    outline: "none",
                                                                    height: "48px",
                                                                    border: "none",
                                                                    position: "absolute"
                                                                }}
                                                                className="input-group-text"
                                                            >
                                                                <i className="fa-solid fa-key"></i>
                                                            </span>
                                                        </>
                                                    }
                                                    className="form-control"
                                                    type={showPassword ? "text" : "password"}
                                                    placeholder="Enter Password"
                                                    component={TextInput}
                                                />
                                                <span
                                                    onClick={toggleShowPassword}
                                                    style={{
                                                        position: 'absolute',
                                                        right: '10px',
                                                        top: '27px',
                                                        transform: 'translateY(-50%)',
                                                        cursor: 'pointer'
                                                    }}
                                                >
                                                    <i className={showPassword ? "fa fa-eye" : "fa fa-eye-slash"}></i>
                                                </span>
                                            </div>
                                            <Link className="frgt" to="/account/forgot-password">Forgot Password?</Link>
                                            <button className="btn-theme btn-login btn-block" type="submit">Login</button>
                                        </Form>
                                    )}
                                </Formik>
                                {/* <button onClick={() => navigate('/account/admin/login')} className="btn-theme btn-login btn-bloc mt-3" type="button">Login with admin</button> */}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <ModalPopup childern={<CreateNewPassword onSubmitCreateNewPassowrd={onSubmitCreateNewPassowrd} hideElement={null} />} show={confirmAlert.show} handleClose={() => setconfirmAlert(prev => ({
                ...prev,
                show: false,
                userId: null,
                dataBaseName: null
            }))} />
        </>
    );
}

export default Login;
