import { Field, Formik, Form  } from 'formik'
import { useState } from 'react';
import * as Yup from 'yup'
import { TextInput } from '../../components';
import { UserService } from '../../api-services';
import { toast } from 'react-toastify';

const ChangePassword = () => {
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setshowConfirmPassword] = useState(false)
    const validationSchema = Yup.object().shape({
        oldPassword: Yup.string().required('oldPassword is required').min(8, 'Password must be at least 8 characters long'),
        password: Yup.string().required('Password is required').min(8, 'Password must be at least 8 characters long'),
        confirmPassword: Yup.string().required('Confirm password is required').oneOf([Yup.ref('password')], 'Passwords must match')
    })
 
    return (
        <div className="add-form">
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <h2 className="text-center">Change Password?</h2>
                    <Formik
                        initialValues={{
                            oldPassword: "",
                            password: '',
                            confirmPassword: '',
                        }}
                        enableReinitialize={true}
                        validationSchema={validationSchema}
                        onSubmit={(values, { setErrors ,resetForm }) => {
                            console.log(values)
                              
                            UserService.ChangePassword(values).then((res)=>{
                              toast.success(res?.data.message)
                              resetForm()
                            }).catch((err)=>{
                                setErrors({ ...err?.response?.data?.message });
                            })
                        }}
                    >
                        {() => (
                            <Form>
                                <div className="fields">
                                    <div className="form-group" style={{ position: "relative" }}>
                                        <Field name="oldPassword" removeMargin={true} shouldDollarRender={true} shouldUserRenderIcon={<> <span style={{
                                            width: "2.5rem",
                                            outline: "none",
                                            height: "48px",
                                            border: "none",
                                            position: "absolute"
                                        }} className="input-group-text"><i className="fa-solid fa-key"></i></span></>} className="form-control" placeholder="Enter Old Password" type={showOldPassword ? "text" : "password"} component={TextInput} />
                                        <span
                                            onClick={() => setShowOldPassword(!showOldPassword)}
                                            style={{
                                                position: 'absolute',
                                                right: '10px',
                                                top: '27px',
                                                transform: 'translateY(-50%)',
                                                cursor: 'pointer'
                                            }}
                                        >
                                            <i className={showOldPassword ? "fa fa-eye" : "fa fa-eye-slash"}></i>
                                        </span>
                                    </div>
                                    <div className="form-group" style={{ position: "relative" }}>
                                        <Field name="password" removeMargin={true} shouldDollarRender={true} shouldUserRenderIcon={<> <span style={{
                                            width: "2.5rem",
                                            outline: "none",
                                            height: "48px",
                                            border: "none",
                                            position: "absolute"
                                        }} className="input-group-text"><i className="fa-solid fa-key"></i></span></>} className="form-control" placeholder="Create New Password" type={showNewPassword ? "text" : "password"} component={TextInput} />
                                        <span
                                            onClick={() => setShowNewPassword(!showNewPassword)}
                                            style={{
                                                position: 'absolute',
                                                right: '10px',
                                                top: '27px',
                                                transform: 'translateY(-50%)',
                                                cursor: 'pointer'
                                            }}
                                        >
                                            <i className={showNewPassword ? "fa fa-eye" : "fa fa-eye-slash"}></i>
                                        </span>
                                    </div>
                                    <div className="form-group" style={{ position: "relative" }}>
                                        <Field name="confirmPassword" removeMargin={true} shouldDollarRender={true} shouldUserRenderIcon={<> <span style={{
                                            width: "2.5rem",
                                            outline: "none",
                                            height: "48px",
                                            border: "none",
                                            position: "absolute"
                                        }} className="input-group-text"><i className="fa-solid fa-key"></i></span></>} className="form-control" type={showConfirmPassword ? "text" : "password"} placeholder="Enter Confirm Password" component={TextInput} />
                                        <span
                                            onClick={() => setshowConfirmPassword(!showConfirmPassword)}
                                            style={{
                                                position: 'absolute',
                                                right: '10px',
                                                top: '27px',
                                                transform: 'translateY(-50%)',
                                                cursor: 'pointer'
                                            }}
                                        >
                                            <i className={showConfirmPassword ? "fa fa-eye" : "fa fa-eye-slash"}></i>
                                        </span>
                                    </div>
                                </div>
                                <button type='submit' className="btn-theme btn-login">Save</button>
                            </Form>)}
                    </Formik>
                </div>
            </div>


        </div>
    )
}

export default ChangePassword