import { Field, Formik, Form } from 'formik'
import * as Yup from 'yup'
import { Link, useNavigate, useParams } from "react-router-dom";
import { TextInput } from '../../components';
import { AuthService } from '../../api-services';
import { useEffect, useState } from 'react';
import './style.css'
const ResetPassword = () => {
    let { token } = useParams();
    let navigate = useNavigate();
    const [checkResetToken, setcheckResetToken] = useState(0)
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setshowConfirmPassword] = useState(false)
    useEffect(() => {
        setcheckResetToken(0)
        AuthService.checkresetToken(token).then((res) => {
            setcheckResetToken(res.success)
        }).catch(err => {
            setcheckResetToken(err.success)

        })
    }, [token])
    const validationSchema = Yup.object().shape({
        password: Yup.string().required('Password is required').min(8, 'Password must be at least 8 characters long'),
        confirmPassword: Yup.string().required('Confirm password is required').oneOf([Yup.ref('password')], 'Passwords must match')
    })
    const onSubmit = (values, setErrors) => {
        values.token = token
        AuthService.ResetPassword(values).then((res) => {
            navigate('/account/login')
        }).catch(err => {

        })

    }
    if (checkResetToken === 0)
        return null;
    return (
        <>
            {
                checkResetToken ?

                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h2 className="text-center">Change Password?</h2>
                                        <Formik
                                            initialValues={{
                                                password: '',
                                                confirmPassword: '',
                                            }}
                                            enableReinitialize={true}
                                            validationSchema={validationSchema}
                                            onSubmit={(values, { setErrors }) => {
                                                onSubmit(values, setErrors)
                                            }}
                                        >
                                            {() => (
                                                <Form>
                                                    <div className="fields">
                                                        <div className="form-group" style={{ position: "relative" }}>
                                                            <Field name="password" removeMargin={true} shouldDollarRender={true} shouldUserRenderIcon={<> <span style={{
                                                                width: "2.5rem",
                                                                outline: "none",
                                                                height: "48px",
                                                                border: "none",
                                                                position: "absolute"
                                                            }} className="input-group-text"><i className="fa-solid fa-key"></i></span></>} className="form-control" placeholder="Enter Password" type={showNewPassword ? "text" : "password"} component={TextInput} />
                                                            <span
                                                                onClick={() => setShowNewPassword(!showNewPassword)}
                                                                style={{
                                                                    position: 'absolute',
                                                                    right: '10px',
                                                                    top: '27px',
                                                                    transform: 'translateY(-50%)',
                                                                    cursor: 'pointer'
                                                                }}
                                                            >
                                                                <i className={showNewPassword ? "fa fa-eye" : "fa fa-eye-slash"}></i>
                                                            </span>
                                                        </div>
                                                        <div className="form-group" style={{ position: "relative" }}>
                                                            <Field name="confirmPassword" removeMargin={true} shouldDollarRender={true} shouldUserRenderIcon={<> <span style={{
                                                                width: "2.5rem",
                                                                outline: "none",
                                                                height: "48px",
                                                                border: "none",
                                                                position: "absolute"
                                                            }} className="input-group-text"><i className="fa-solid fa-key"></i></span></>} className="form-control" type={showConfirmPassword ? "text" : "password"} placeholder="Enter Confirm Password" component={TextInput} />
                                                            <span
                                                                onClick={() => setshowConfirmPassword(!showConfirmPassword)}
                                                                style={{
                                                                    position: 'absolute',
                                                                    right: '10px',
                                                                    top: '27px',
                                                                    transform: 'translateY(-50%)',
                                                                    cursor: 'pointer'
                                                                }}
                                                            >
                                                                <i className={showConfirmPassword ? "fa fa-eye" : "fa fa-eye-slash"}></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <button type='submit' className="btn-theme btn-login">Submit</button>
                                                </Form>)}
                                        </Formik>
                                    </div>
                                    <Link className="text-theme" to="/account/login"><i style={{ marginBottom: "21px" }} className="fa-solid fa-arrow-left me-2"></i> Back to Login</Link>
                                </div>
                            </div>
                        </div>

                    </div>
                    :
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 offset-lg-3 col-12">
                                <div className="success-inner">
                                    <h1>Oops, this link is expired</h1>
                                    <p>This URL is not valid anymore.</p>
                                    <Link className="text-theme" to="/account/login"><i className="fa-solid fa-arrow-left me-2"></i> Back to Login</Link>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>

    )
}

export default ResetPassword;