class TextPositonService {

    static patientReportProgress = (progress) => {
        if (progress) {

            let medicalRecordProgressBar = progress[0] === "Extracted" ? progress[0] : progress
            let medicalRecordsUploadingBar = progress[0] === "Uploading" ? progress[0] : progress
            let medicalGeneratingBar = progress[0] === "Generating" ? progress[0] : progress
            let refiningStage = progress[0] === "Refining" ? progress[0] : progress;

            switch (progress[0]) {
                case medicalGeneratingBar:
                    return '-65px'
                case 'Successful':
                    return '-40px'
                case refiningStage:
                    return '-56px'
                case medicalRecordsUploadingBar:
                    return '-40px'
                case medicalRecordProgressBar:
                    return '-40px'
                default:
                    if (progress[0] < 50) {
                        return '-15px'
                    }
                    if (progress[0] > 50) {
                        return '-60px'
                    }
            }
        }

    }

    static medicationReportProgress = (progress) => {
        if (progress) {
            let medicalGeneratingBar = progress[0] === "Generating" ? progress[0] : progress
            switch (progress[0]) {
                case medicalGeneratingBar:
                    return '-70px'
                case 'Successful':
                    return '-40px'
                case 'Uploading':
                    return '-40px'
                case 'Extracted':
                    return '-40px'
                default:
                    if (progress < 50) {
                        return '-15px'
                    }
                    if (progress > 50) {
                        return '-60px'
                    }
            }
        }

    }

    static nursingReportProgress = (progress) => {
        if (progress) {
            let medicalGeneratingBar = progress[0] === "Generating" ? progress[0] : progress
            const categoryArray = ["ES", "SCH", "SCL", "CP", "BSCP", "PF"];
            let nursingServiceBar;
            const progressIndex = categoryArray.indexOf(progress[0].replace(/\d+$/, ''));
            if (progressIndex !== -1) {
              nursingServiceBar = progress[0];
            } else {
              nursingServiceBar = progress;
            }
            switch (progress[0]) {
                case medicalGeneratingBar:
                    return '-70px'
                case 'Successful':
                    return '-40px'
                case 'Uploading':
                    return '-40px'
                case 'Extracted':
                    return '-40px'
                case 'converting...':
                    return '-40px'


                case nursingServiceBar:

                    return '-40px'

                default:
                    if (progress < 50) {
                        return '-15px'
                    }
                    if (progress > 50) {
                        return '-60px'
                    }
            }
        }

    }


}

export default TextPositonService;