import { Navigate, Outlet } from "react-router-dom";
import Constants from '../Constants';
import LoggedInUserService from '../services/AuthService'
const PublicRoute = ({ patientredirectPath = '/account/login' ,  adminRedirectPath }) => {
    const tdata = LoggedInUserService.GetLoggedInUserData();
    if (Constants?.getAuthtoken()?.isToken &&tdata && tdata?.role === 0) {
        return <Navigate to={Constants?.getAuthtoken()?.isToken ? "/patient/list"  : patientredirectPath} replace />;
    }
    else if(Constants?.getAuthtoken()?.isToken && tdata && tdata?.role === 1) {
        return <Navigate to={Constants?.getAuthtoken()?.isToken ? "/admin/list"  : adminRedirectPath} replace />;
    }
    return <Outlet />;
};
export default PublicRoute;