import React, { useEffect, useState } from "react";
import { SuperAdminService } from "../../../api-services";
import { RenderIf, Dialog } from "../../../components";
import AddHospitalDetailForm from "./components/AddHospital";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../users/components/CircularLoader";
import ReactPagination from "../../../components/common/Pagination";
import DialogBox from "../../../components/common/DialogBox";
import { IconButton } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { Skeleton } from "@mui/material";
import EditIcon from "../../../assets/images/edit.svg"
const HospitalList = () => {
   const [isAddCaseFromOpen, setAddCaseFromOpen] = useState(false);
   const [hospitalList, sethospitalList] = useState([]);
   const location = useLocation();
   const [currentPage, setCurrentPage] = useState(1);
   const [totalCount, settotalCount] = useState("");
   const [totalPages, settotalPages] = useState(0);
   const [searchText, setsearchText] = useState("")
   const [showEntries, setshowEntries] = useState(10)
   const navigate = useNavigate();
   const [isModalOpen, setIsModalOpen] = useState(false);
   const [errorResponseMsg, seterrorResponseMsg] = useState('')
   const [formData, setformData] = useState({})
   const [isSubmittingForm, setisSubmittingForm] = useState(false);
   const [loading, setLoading] = useState(false);
   const [confirmDelete, setconfirmDelete] = useState({
      show: false,
      id: null
   })
   const [errorMessage, setErrorMessage] = useState('')

   useEffect(() => {
      const debounceSearchTimeOut = setTimeout(() => {
         getHospital();
      }, 500)
      return () => {
         clearTimeout(debounceSearchTimeOut)
      }
   }, [currentPage, searchText, showEntries, location.search])

   const addHospitalFrom = () => {
      const newPath = `${location.pathname}?add`;
      navigate(newPath);
      setAddCaseFromOpen(true)
   }

   const getHospital = async () => {
      setLoading(true)
      SuperAdminService.GetHospital(currentPage, showEntries, searchText).then(resp => {
         sethospitalList(resp.data.reverse());
         settotalPages(resp?.totalPages);
         settotalCount(resp?.totalCount)
         setLoading(false)
      }).catch((err) => {
         setLoading(false)
         if (err?.response?.status === 401) {
            localStorage.clear();
            navigate('/account/admin/login')

         }
      })
   }

   const addHospital = async (formPayload, setErrors) => {
      console.log(formPayload)
      setisSubmittingForm(true);
      const formdata = new FormData();
      formdata.append("hospitalName", formPayload?.hospitalName)
      //  formdata.append("hospitalEmail", formPayload?.hospitalEmail)
      formdata.append("hospitalPhoneNumber", formPayload?.hospitalPhoneNumber)
      formdata.append("hospitalAddress", formPayload?.hospitalAddress)
      formdata.append("firstName", formPayload?.firstName)
      formdata.append("lastName", formPayload?.lastName)
      formdata.append("contactNumber", formPayload?.contactNumber)
      formdata.append("contactEmail", formPayload?.contactEmail)
      formdata.append("subdomain", formPayload?.subdomain)
      formdata.append("dataBaseName", formPayload?.dataBaseName)
      formdata.append("dataBaseUser", formPayload?.dataBaseUser)
      formdata.append("databaseIP", formPayload?.databaseIP)
      formdata.append("dataBasePassword", formPayload?.dataBasePassword)
      formdata.append("chatGptApiKey", formPayload?.chatGptApiKey)
      formdata.append("hospitalLogo", formPayload?.hospitalLogo);
      formdata.append('hospitalfile', formPayload?.hospitalfile)

      if (formPayload?.id) {

         await SuperAdminService.UpdateHospital(formPayload, formPayload?.id).then((res) => {
            setTimeout(() => {
               getHospital();
            }, 2000)
            setAddCaseFromOpen(false);
            setisSubmittingForm(false);
         }).catch(err => {
            setisSubmittingForm(false);
         })

      }
      else {
         await SuperAdminService.AddHospital(formdata).then((response) => {
            setTimeout(() => {
               getHospital();
            }, 2000)
            setAddCaseFromOpen(false);
            setisSubmittingForm(false);
         }).catch((err) => {
            console.log(err, 'err')
            setisSubmittingForm(false);
            if (Object.keys(err?.response?.data?.message)?.length > 0) {
               let getelementById = document.querySelector(`#${Object.keys(err?.response?.data?.message)}`)
               if (getelementById != null) {
                  getelementById.scrollIntoViewIfNeeded()
               }
               setErrors({ ...err?.response?.data?.message });
            }
         })
      }


   }

   const handlePageChange = (event, pageNumber) => {
      setCurrentPage(pageNumber);
   };


   const handledeletePatient = async (id) => {
      try {
         let res = await SuperAdminService.deleteHospital(id);
         setLoading(false)
         sethospitalList((prev) => {
            let array = [...prev]
            return array.filter((item) => item._id.toString() !== id?.toString())
         })
         setconfirmDelete({
            show: false,
            id: null
         })
      }
      catch (err) {
         setLoading(false)
      }
   }


   // if (loading && !isAddCaseFromOpen)
   //    return <Loader />
   return (
      <>

         <div className="listing">

            <div class={`title d-flex ${!isAddCaseFromOpen ? 'justify-content-between' : 'justify-content-end'} align-items-center`}>

               {!isAddCaseFromOpen && <span>List of All Facility</span>}
               {
                  !isAddCaseFromOpen
                     ?
                     <button class="btn btn-viewPdf" onClick={() => addHospitalFrom()} ><i class="fa-solid fa-plus me-2"></i> Add Facility</button>
                     :
                     <div style={{ display: "flex", justifyContent: "center", alignItems: "flex-start", gap: "4" }}>
                        <button class="btn btn-viewPdf" onClick={() => { setAddCaseFromOpen(false); }}><i class="fa-solid fa-arrow-left me-2"></i>Back</button>
                     </div>

               }

            </div>

            <RenderIf shouldRender={isAddCaseFromOpen}>
               <AddHospitalDetailForm addHospital={addHospital} isSubmittingForm={isSubmittingForm} onCancel={() => { setAddCaseFromOpen(false) }} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} errorResponseMsg={errorResponseMsg} />
            </RenderIf>
            <RenderIf shouldRender={!isAddCaseFromOpen}>
               <div id="myTable_wrapper" class="dataTables_wrapper no-footer">
                  <div class="dataTables_length" id="myTable_length">
                     <label>
                        Show
                        <select value={showEntries} onChange={(e) => setshowEntries(parseInt(e.target.value))} name="myTable_length" aria-controls="myTable" class="">
                           <option value="10">10</option>
                           <option value="25">25</option>
                           <option value="50">50</option>
                           <option value="100">100</option>
                        </select>
                        entries
                     </label>
                  </div>
                  <div id="myTable_filter" class="dataTables_filter"><label>Search:<input value={searchText} onChange={(e) => setsearchText(e.target.value)} type="search" class="" placeholder="" aria-controls="myTable" /></label></div>
                  <div className="patient_list">
                     <table id="myTable" class="display dataTable no-footer" aria-describedby="myTable_info">
                        <thead>
                           <tr>
                              <th>Facility Name</th>
                              <th>Facility Phone no</th>
                              <th>Facility Address </th>
                              <th>Name </th>
                              <th>ContactPhone Number </th>
                              <th>Email Address </th>
                              <th>Website url </th>
                              <th>DataBase Name </th>
                              <th></th>
                              <th></th>
                           </tr>
                        </thead>
                        <tbody>
                           {

                              loading ?
                                 (
                                    Array.from(new Array(4)).map((_, index) => (
                                       <tr key={index}>
                                          <td><Skeleton variant="rectangular" height={40} /></td>
                                          <td><Skeleton variant="rectangular" height={40} /></td>
                                          <td><Skeleton variant="rectangular" height={40} /></td>
                                          <td><Skeleton variant="rectangular" height={40} /></td>
                                          <td><Skeleton variant="rectangular" height={40} /></td>
                                          <td><Skeleton variant="rectangular" height={40} /></td>
                                          <td><Skeleton variant="rectangular" height={40} /></td>
                                          <td><Skeleton variant="rectangular" height={40} /></td>
                                       </tr>
                                    ))
                                 )
                                 :
                                 hospitalList?.map((x, index) => {

                                    return (
                                       <tr class={index % 2 === 0 ? "odd" : 'even'}>
                                          <td>{x?.hospitalName}</td>
                                          <td>{x?.hospitalPhoneNumber}</td>
                                          <td>{x?.hospitalAddress}</td>
                                          <td>{x?.firstName}  {x?.lastName}</td>
                                          <td>{x?.contactNumber}</td>
                                          <td>{x?.contactEmail}</td>
                                          <td>
                                             <a
                                                target="_blank"
                                                href={`${process.env.REACT_APP_API_HTTP_URL}://${x?.subdomain}.${process.env.REACT_APP_API_DOMAIN_URL}`}
                                                style={{ cursor: "pointer" }}
                                                rel="noopener noreferrer"
                                             >
                                                {`${x?.subdomain}.${process.env.REACT_APP_API_DOMAIN_URL}`}
                                             </a>
                                          </td>
                                          <td>{x?.dataBaseName}</td>
                                          <td>
                                             <i onClick={() => { navigate('/admin/list?edit', { state: x }); setAddCaseFromOpen(true) }} class="bi bi-pencil-square text-center"><img src={EditIcon} alt="" /></i>
                                          </td>
                                          <td>
                                             <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <IconButton sx={{ color: "#C53E4E" }} onClick={() => setconfirmDelete({
                                                   show: true,
                                                   id: x?._id
                                                })}  >
                                                   <DeleteIcon />
                                                </IconButton>
                                             </span>
                                          </td>

                                       </tr>
                                    )


                                 })}
                        </tbody>
                     </table>
                  </div>

                  <div class="dataTables_info" id="myTable_info" role="status" aria-live="polite">Showing 1 to {showEntries} of {totalCount} entries</div>

                  {totalPages > 1 && <div class="dataTables_paginate paging_simple_numbers" id="myTable_paginate">
                     <ReactPagination
                        activePage={currentPage}
                        totalItemCount={totalPages}
                        onChange={handlePageChange}
                     />
                  </div>}
                  {/* <div class="dataTables_paginate paging_simple_numbers" id="myTable_paginate"><a class="paginate_button previous disabled" aria-controls="myTable" aria-disabled="true" aria-role="link" data-dt-idx="previous" tabindex="-1" id="myTable_previous">Previous</a><span><a class="paginate_button current" aria-controls="myTable" aria-role="link" aria-current="page" data-dt-idx="0" tabindex="0">1</a></span><a class="paginate_button next disabled" aria-controls="myTable" aria-disabled="true" aria-role="link" data-dt-idx="next" tabindex="-1" id="myTable_next">Next</a></div> */}
               </div>
            </RenderIf>
         </div>
         <DialogBox open={confirmDelete.show} handleSubmit={() => handledeletePatient(confirmDelete?.id)} handleClose={() => setconfirmDelete({ show: false, id: null })} title={'Are you sure want to delete?'} />
      </>
   )

}


export default HospitalList