import React, { useEffect, useRef, useState } from "react";
import { Outlet, NavLink, useNavigate, useLocation } from "react-router-dom";
import AuthServiceProvider from "../services/AuthService";
import DialogBox from "../components/common/DialogBox";
import { AuthService, SuperAdminService } from "../api-services";
import { Autocomplete, Button, TextField } from "@mui/material";
const eastonLogo = require("../assets/images/logo.png");
const userLogo = require("../assets/images/user.png");
const tdata = AuthServiceProvider.GetLoggedInUserData();
const isAdmin = localStorage.getItem("isAdmin")

const Home = (props) => {


  const navigate = useNavigate();
  const loggedInUserDetailsSubscriber = useRef(null);
  const location = useLocation();
  const [profilePictureImageUrl, setProfilePictureImageUrl] = useState("");
  const [open, setOpen] = useState(false)
  const [buildingList, setbuildingList] = useState(null);
  const [tokenPayload, settokenPayload] = useState(null)
  const [selectedFacilityData, setSelectedFacilityData] = useState([])

  useEffect(() => {
    if (window.location.pathname === "/") {
      navigate('/patient/list')
    }
    const loggedInUser = AuthServiceProvider.GetLoggedInUserData();
    setProfilePictureImageUrl(loggedInUser.profileImageUrl);
    registerLoggedInUserDetailsSubscriber();

    return () => {
      loggedInUserDetailsSubscriber.current.unsubscribe();
    }

  }, [])



  const registerLoggedInUserDetailsSubscriber = () => {
    loggedInUserDetailsSubscriber.current = AuthServiceProvider.LoggedInUserDetails.subscribe(value => {
      const imageUrl = value.profileImageUrl + "?" + Math.random();
      setProfilePictureImageUrl(imageUrl);
    })
  }
  const logout = () => {
    try {
      AuthService.logout().then((res) => {
        localStorage.clear();
        navigate('/account/login');
      })
    }
    catch (err) {

    }
  }

  const getHospitals = async () => {
    SuperAdminService.GetHospital(null, null, null, "buildingList").then(resp => {
      setbuildingList(resp.data.reverse());
    }).catch((err) => {
  
    })
  }

  const findfacilty = async (id) => {
    try {
      let resp = await SuperAdminService.SwitchFacility(id);
      settokenPayload(resp?.token)
    }
    catch (err) {

    }
  }

  const handleOnChangeSelect = async (event, selectedOption) => {
    const { value } = selectedOption != null ? selectedOption : {}
    setSelectedFacilityData(value)
    const id = value?._id;
    if (id) {
      localStorage.setItem('Facility', JSON.stringify(value.hospitalName))
      await findfacilty(id)
    } else if(id === undefined) {
      localStorage.removeItem('Facility')
      settokenPayload(null)
    }
  };

  return <>
    <TopNavBar
      profileImageUrl={profilePictureImageUrl}
      open={open} setOpen={setOpen}
      navigate={navigate}
      logout={logout}
      getHospitals={getHospitals}
      buildingList={buildingList}
      handleOnChangeSelect={handleOnChangeSelect}
    />
    {/* Home Routes render section */}
    <Sidebar selectedFacilityToken={tokenPayload} selectedFacilityData={selectedFacilityData}/>
  </>
}

const TopNavBar = ({ profileImageUrl, open, setOpen, logout, getHospitals, buildingList, handleOnChangeSelect }) => <nav className="navbar navbar-expand-lg bg-body-tertiary top_sidebar">
  <div className="container-fluid">

    <NavLink className="nav-link" to="dashboard">
      <img alt="Company-Logo" src={eastonLogo} className="navbar-brand img-fluid" />
    </NavLink>

    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav ms-auto mb-2 mb-lg-0 d-flex align-items-center">
        {isAdmin && (
        <li className="nav-item">
          <Autocomplete  
            className="select_des"
            style={{marginBottom:"10px"}}
            id="free-solo-demo"
            onChange={handleOnChangeSelect}
            onOpen={getHospitals}
            options={buildingList != null ? buildingList?.map((item) => ({ label: item?.hospitalName, value: item })) : []}
            renderInput={(params) => 
              <TextField {...params} onChange={(e) => {}}
              InputProps={{
                ...params.InputProps,
                style: { height: 38, display: 'flex', alignItems: 'center' },
              }}
              placeholder="Search Facility"
            />}
          />
        </li>
        )}
        <li className="nav-item">
          <Button variant="outlined" onClick={() => setOpen(true)}>
            Logout
          </Button>
          <DialogBox text="Logout" title={'Are you sure want to Logout'} open={open} handleSubmit={() => {
            logout()
          }} handleClose={() => setOpen(false)} />
        </li>
      </ul>
    </div>
  </div>
</nav>
const Sidebar = ({ selectedFacilityToken, selectedFacilityData }) => {
  return (
    <div style={{ overflow: "hidden" }} className="row m-0">
      <nav className="col-md-3 col-lg-2 d-md-block bg-light sidebar">
        <div className="position-sticky">
          <ul className="nav nav-pills flex-column mb-auto">

            <li className="nav-item  m-2">
              <NavLink to="/patient/dashboard" className="nav-link">
                Dashboard
              </NavLink>
            </li>
            <li className="nav-item m-2">
              <NavLink to="/patient/list" className="nav-link">
                List
              </NavLink>
            </li>
            {
              tdata?.isAdmin ? <li className="nav-item m-2">
                <NavLink to="/patient/users" className="nav-link">
                  Users
                </NavLink>
              </li> : <></>
            }

            <li className="nav-item m-2">
              <NavLink to="/patient/profile" className="nav-link">
                Profile
              </NavLink>
            </li>
            {
              tdata?.isAdmin ?
                <li className="nav-item m-2">
                  <NavLink to="/patient/auditLogs" className="nav-link">
                    Audit Logs
                  </NavLink>
                </li> : <></>
            }
            {/* Add more sidebar items as needed */}
          </ul>
        </div>
      </nav>

      <main role="main" className="col-md-9 ml-sm-auto col-lg-10 sidebar_container">
        <Outlet context={{selectedFacilityToken, selectedFacilityData}}/>
      </main>
    </div>
  );
};



export default Home;