import { CircularProgress, Backdrop, Box } from '@mui/material';

const Loader = () => {
    return (
        <Backdrop open={true}
            sx={{
                color: (theme) => theme.palette.primary.main,
                zIndex: (theme) => theme.zIndex.drawer + 1,
                backgroundColor: 'transparent',
            }}>
            <Box sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                overflow: "hidden",
                color: "#86BD3B",
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <CircularProgress
                    size={"8vh"}
                />
            </Box>
        </Backdrop>
    );
};
export default Loader;