import React from 'react'
import { Route, Routes } from 'react-router-dom';
import PdfConverter from '../pages/converter/PdfConverter';
import { Dashboard, Patient, Users, Profile, PdfPreview, AuditLoglList } from '../pages'
import ChangePassword from '../pages/account/ChangePassword';


const Home = (props) => {

    return <Routes>
        <Route path="patient">
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="list" element={<Patient />} />
            <Route path="pdf-Preview" element={<PdfPreview />}></Route>
            <Route path="users" element={<Users />} />
            <Route path="profile" element={<Profile />} />
            <Route path="pdf-converter" element={<PdfConverter />} />
            <Route path='auditLogs' element={<AuditLoglList />} />
            <Route path="change-password" element={<ChangePassword />} />
        </Route>
    </Routes>
}

export default Home;