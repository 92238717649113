import React from 'react';
import RenderIf from '../render-if/Renderif';
import ErrorMessage from './ErrorMessage';
import { useFormikContext } from 'formik'; // Import useFormikContext

const TextInput = ({ removeMargin = false, ...props }) => {
    return (
        <React.Fragment>
            {!removeMargin ? (
                <div className="form-field">
                    <Input {...props} />
                </div>
            ) : (
                <Input {...props} />
            )}
        </React.Fragment>
    );
}

export default TextInput;

const Input = ({
    label,
    type = "text",
    children,
    field,
    form,
    className,
    disabled,
    inputFieldChildren,
    shouldDollarRender,
    shouldUserRenderIcon,
    isDollarSignRender,
    ...props
}) => {
    const formik = useFormikContext();

    let isDisabled = disabled ? "disabled" : "";
    let disabledClass = disabled ? "cursor-not-allowed" : "";

    const formatInput = (event) => {
        const inputValue = event.target.value ? event.target.value.replace(/,/g, '') : '';
        const formattedValue = inputValue === "NaN" ? "" : parseInt(inputValue).toLocaleString();

        formik.setFieldValue(field.name, formattedValue === "NaN" ? "" : formattedValue);
    };

    return (
        <React.Fragment>
            {label && <label className='label-style'> {label} </label>}

            <div className="input-field">
                {
                    shouldDollarRender ?


                        isDollarSignRender ?
                            <div>
                                {shouldUserRenderIcon}
                                <input
                                    type={type}
                                    {...field}
                                    {...props}
                                    tabIndex={props.tabIndex ? props.tabIndex : ""}
                                    className={`input-field-style-dollar-sign ${disabledClass}`}
                                    disabled={isDisabled}
                                    onChange={formatInput}
                                    value={formik.values[field?.name]}
                                />
                            </div>
                            :
                            <div>
                                {shouldUserRenderIcon}
                                <input
                                    type={type}
                                    {...field}
                                    {...props}
                                    tabIndex={props.tabIndex ? props.tabIndex : ""}
                                    className={`input-field-style-dollar-sign ${disabledClass}`}
                                    disabled={isDisabled}
                                // onChange={formatInput}
                                // value={formik.values[field?.name]}
                                />
                            </div>
                        :
                        <input
                            type={type}
                            {...field}
                            {...props}
                            tabIndex={props.tabIndex ? props.tabIndex : ""}
                            className={`${className ? className : 'input-field-style'} ${disabledClass}`}
                            disabled={isDisabled}
                        />

                }

                {inputFieldChildren}
                <RenderIf shouldRender={form && field}>
                    <ErrorMessage form={form} field={field} />
                </RenderIf>
            </div>
            {children}
        </React.Fragment>
    );
};
