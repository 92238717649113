import React from 'react'
import {  Route, Routes } from 'react-router-dom';
import { BuildingList, Dashboard, HospitalList } from '../pages/superadmin'
import { PdfPreview } from '../pages';


const Home = (props) => {


    return <Routes>
        <Route path="dashboard" element={<Dashboard/>}/>
        <Route path='list' element={<HospitalList/>}/>
        <Route path='building' element={<BuildingList/>}/>
        <Route path="pdf-Preview" element={<PdfPreview />}/>
    </Routes>
}

export default Home;