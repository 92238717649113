import React, { useState } from 'react';
import { Field, Formik, Form } from 'formik'
import * as Yup from 'yup'
import { TextInput, Checkbox } from '../../../components';


const AddEditUserForm = ({ onSubmit, onCancel, isSubmittingForm  , editUserInfo}) => {
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setshowConfirmPassword] = useState(false)
    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required('First name is required'),
        lastName: Yup.string().required('Last name is required'),
        email: Yup.string().required('Email is required').email('Please enter a valid email'),
        mobileNumber: Yup.string().required('Mobile number is required'),
        password: Yup.string().required('Password is required').min(8, 'Password must be at least 8 characters long'),
        confirmPassword: Yup.string().required('Confirm password is required').oneOf([Yup.ref('password')], 'Passwords must match')
    })

    return (
        <Formik
            initialValues={{
                firstName: editUserInfo?.firstName ||  '',
                lastName:  editUserInfo?.lastName ||  '',
                email: editUserInfo?.email ||  '',
                mobileNumber: editUserInfo?.mobileNumber ||  '',
                password: '',
                confirmPassword: '',
                isAdmin: false,
            }}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={(values ,  { setErrors }) => {
                onSubmit(true,values , setErrors);
            }}
        >
            {() => (
                <Form>
                    <div className="add-form p-0">
                        <div className="p-4">
                            <h2 className="title"> {editUserInfo!=null ? "Update User" : "Add User"}
                            </h2>
                            <div className="row pt-3">
                                <div className="col-6">
                                    <div className="form-group">
                                        <Field name="firstName" placeholder="Enter First Name" label="First Name" component={TextInput} />
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className="form-group">
                                        <Field name="lastName" placeholder="Enter Last Name" label="Last Name" component={TextInput} />
                                    </div>
                                </div>



                                <div className="col-6">
                                    <div className="form-group">
                                        <Field disabled={editUserInfo} name="email" placeholder="Enter Email" label="Email" component={TextInput} />
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className="form-group">
                                        <Field name="mobileNumber" placeholder="Enter Mobile Number" label="Mobile Number" component={TextInput} />
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className="form-group" style={{ position : "relative"}}>
                                        <Field name="password" type={ showNewPassword ? "text" : "password"} placeholder="Enter Password" label= {editUserInfo!=null ? "New Password" : "Password"}  component={TextInput} />
                                        <span
                                                        onClick={() => setShowNewPassword(!showNewPassword)}
                                                        style={{
                                                            position: 'absolute',
                                                            right: '10px',
                                                            top: '54px',
                                                            transform: 'translateY(-50%)',
                                                            cursor: 'pointer'
                                                        }}
                                                    >
                                                        <i className={showNewPassword ? "fa fa-eye" : "fa fa-eye-slash"}></i>
                                                    </span>
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className="form-group" style={{ position : "relative"}}>
                                        <Field name="confirmPassword" type={showConfirmPassword ? "text" : "password"}  placeholder="Confirm Password" label="Confirm Password" component={TextInput} />
                                        <span
                                                        onClick={() => setshowConfirmPassword(!showConfirmPassword)}
                                                        style={{
                                                            position: 'absolute',
                                                            right: '10px',
                                                            top: '54px',
                                                            transform: 'translateY(-50%)',
                                                            cursor: 'pointer'
                                                        }}
                                                    >
                                                        <i className={showConfirmPassword ? "fa fa-eye" : "fa fa-eye-slash"}></i>
                                                    </span>
                                    </div>
                                </div>

                                {/* <div className="col-12">
                                    <div className="form-group">
                                        <Field
                                            name="isAdmin"
                                            label="Admin"
                                            position="left"
                                            component={Checkbox}
                                        />
                                    </div>
                                </div> */}
                            </div>

                        </div>
                        <div className="btns text-center">
                            <button disabled={isSubmittingForm} className="btn-theme" type="submit">{editUserInfo!=null ? "Update" :"Save"}</button>
                            <button className="btn-secondary form-buttons-margin" onClick={onCancel} >Cancel</button>
                        </div>
                    </div>
                </Form>
            )
            }
        </Formik>
    )


}

export default AddEditUserForm;