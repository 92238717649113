var CryptoJS = require("crypto-js");
class Constants {
    static ApiUrl = {
        create: '/add-patient',
        update: '/update_patient_nta',
        getMasterMedicationData: "/getMasterMedicationList",
        getMasterNtaICD10Data: "/getMasterNtaIcd_10",
        generate: '/generatePdf',
        generateuintArray: '/generateuintArray',
        pdfConverter: "/upload-pdf",
        getErrorLog: "/getErrorlog",
        deleteErrorlog: "/deleteErrorlog",
        getocrPdfUrl: "/getconvertedPdfUrl",

        user: {
            add: '/api/user/add',
            getUsers: "/api/user/getUsers",
            updateProfile: "/api/user/profile",
            getAuditLogs: "/api/user/getLogData"
        },

        case: {
            getPatient: '/api/patient/list',
            checkEmail: "/checkEmail",
            deletePatient: "/api/patient/deletePatient",
            getPatientById: "/api/patient/getSinglePatient",
            updateUser: "/api/patient/editUser",
            changePassword: "/api/patient/changePassword"
        },
        auth: {
            sigin: '/api/auth/signin',
            forgotPassword: '/api/auth/forgotPaasword',
            resetPassword: '/api/auth/resetpassword',
            checkresetToken: "/api/auth/checkresetToken",
            sendPdfandWordLink: "/api/auth/sendPdfandWordLink",
            logout: "/logout",
            createNewPassword: '/api/auth/createNewPassword',
        },
        adminauth: {
            sigin: '/api/admin/auth/signin',
        },
        superAdmin: {
            create: "/api/admin/hospital/create",
            update: "/api/admin/hospital/update",
            getHospital: '/api/admin/hospital/list',
            deleteHospital: "/api/admin/hospital/delete",
            switchHospital: "/api/admin/hospital/switchfacilty",

        }
    }

    static Dropdowns = {

        States: [
            { value: "New york", text: "New york" },
            { value: "Washington DC", text: "Washington DC" },
            { value: "Florida", text: "Florida" },
            { value: "Texas", text: "Texas" },
            { value: "Kansas", text: "Kansas" },
            { value: "Indinapolis", text: "Indinapolis" },
            { value: "Pheladelphia", text: "Pheladelphia" },
            { value: "New Jersey", text: "New Jersey" },
            { value: "Houston", text: "Houston" },
            { value: "California", text: "California" }
        ],
        Gender: [
            { value: "Male", text: "Male" },
            { value: "Female", text: "Female" }
        ],

        CaseTypes: [
            { value: "Multiple", text: "Multiple" },
            { value: "Individual", text: "Individual" },
        ],

        LevelOfPain: [
            { value: "1", text: "1" },
            { value: "2", text: "2" },
            { value: "3", text: "3" },
            { value: "4", text: "4" },
            { value: "5", text: "5" },
            { value: "6", text: "6" },
            { value: "7", text: "7" },
            { value: "8", text: "8" },
            { value: "9", text: "9" },
            { value: "10", text: "10" }
        ],

        BodyParts: [
            { value: "head", text: "Head" },
            { value: "neck", text: "Neck" },
            { value: "shoulders", text: "Shoulders" },
            { value: "arms", text: "Arms" },
            { value: "elbows", text: "Elbows" },
            { value: "wrists", text: "Wrists" },
            { value: "hands", text: "Hands" },
            { value: "chest", text: "Chest" },
            { value: "back", text: "Back" },
            { value: "abdomen", text: "Abdomen" },
            { value: "hips", text: "Hips" },
            { value: "legs", text: "Legs" },
            { value: "knees", text: "Knees" },
            { value: "ankles", text: "Ankles" },
            { value: "feet", text: "Feet" }
        ],

    }

    static getAuthtoken = () => {
        let token = localStorage.getItem("authtoken")
        if (!token || token === null || token === undefined) { return { isToken: false } }
        return { token: token, isToken: true }

    }

    static encrypt(text) {
        const ss = process.env.REACT_APP_LOCAL_CRYPTO_SECRET;
        return CryptoJS.AES.encrypt(text, process.env.REACT_APP_LOCAL_CRYPTO_SECRET).toString();
    }
    static decrypt(text) {
        const ss = process.env.REACT_APP_LOCAL_CRYPTO_SECRET;
        var bytes = CryptoJS.AES.decrypt(text, process.env.REACT_APP_LOCAL_CRYPTO_SECRET);
        return bytes.toString(CryptoJS.enc.Utf8);
    }

}


export default Constants;