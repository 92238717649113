import React, { useState, useEffect } from "react";

const SessionTimeout = ({ history }) => {
    const [lastActivityTime, setLastActivityTime] = useState(new Date());

    useEffect(() => {
        const logoutAfterInactivity = () => {
            localStorage.removeItem("authtoken");
            window.location.replace("/");
        };

        const inactivityTimer = setTimeout(() => {
            logoutAfterInactivity();
        }, 8 * 60 * 60 * 1000); // Set to 8 hours

        const handleActivity = () => {
            setLastActivityTime(new Date());
        };

        // // List of events to track user activity
        const EVENTS = ["load", "mousemove", "mousedown", "click", "scroll", "keypress"];

        // Attach event listeners for each event
        EVENTS.forEach(event => {
            window.addEventListener(event, handleActivity);
        });

        // Cleanup function to remove event listeners
        return () => {
            clearTimeout(inactivityTimer);
            EVENTS.forEach(event => {
                window.removeEventListener(event, handleActivity);
            });
        };
    }, [lastActivityTime, history]);

    return null;
};

export default SessionTimeout;
