import React, { useEffect, useState } from 'react'
import { Field, Formik, Form } from 'formik'
import * as Yup from 'yup'
import { FileUpload, TextInput } from '../../components'
import { useNavigate } from 'react-router-dom'
import { UserService } from '../../api-services'
import LoggedInUserService from '../../services/AuthService'
import ChangePassword from '../account/ChangePassword'
import { toast } from 'react-toastify'
const userLogo = require("../../assets/images/user.png")

const Profile = () => {
  let navigate = useNavigate();
   const [user, setUser] = useState({
    firstName : "",
    lastName :"",
    email :"",
    profilepic :""
   })
  const [profilefileName, setprofilefileName] = useState({
    fileName : "",
    imageUrl: null
  });
  useEffect(() => {
    const data = LoggedInUserService.GetLoggedInUserData();
    const imageUrl = data?.profileImageUrl + "?" + Math.random();
    setUser(data)
    setprofilefileName({imageUrl: imageUrl});
  }, [])
 

  const validationSchema = Yup.object().shape({
    email: Yup.string().required('Email is required').email('Please enter a valid email'),
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
  })
  const onProfileUpload = (files, setFieldValue) => {
    
    setprofilefileName(files.file.name);
    setprofilefileName((prev)=>({...prev , fileName : files.file.name , imageUrl : URL.createObjectURL(files?.file)}))
    setFieldValue('profilepic', files?.file)
  }
  const onSubmit = (values, setErrors) => {
    let formData = new FormData();
    formData.append('firstName',values?.firstName)
    formData.append('lastName',values?.lastName)
    formData.append('email',values?.email)
    formData.append('profilepic',values?.profilepic)

    UserService.UpdateProfile(formData).then((res)=>{
      toast.success('Profile Updated Successfully')
      LoggedInUserService.UpdateLoggedInUserSession(res?.data?.token);
    }).catch(err=>{

    })

  }
  return (
    <Formik
      initialValues={{...user}}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={(values, { setErrors }) => {
        onSubmit(values)
      }}
    >
      {({ errors, touched, isSubmitting, setFieldValue }) => {
        return (
          <Form>
            <div className="wrapper add-form">
              <div className="content">
              <div className="d-flex justify-content-between align-items-center">
                <div className="title">
                  <h2>Personal Information</h2>
                  <p>Update your photo and personal details here.</p>
                </div>
                <div  style={{ marginBottom :"10px"}} className="mb-40">
                <button onClick={(e)=>{ e.preventDefault(); navigate(
                  "/patient/change-password"
                )} } className="btn btn-viewPdf"> Change Password</button>
                </div>
                </div>
                <div style={{ marginTop :"20px"}} className="card mb-5">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <Field name="firstName" type="text" removeMargin={true} className="form-control" label="First Name" placeholder="Enter here" component={TextInput} />
                      </div>
                      <div className="form-group">
                        <Field name="lastName" type="text" removeMargin={true} className="form-control" label="Last Name" placeholder="Enter here" component={TextInput} />
                      </div>
                      <div className="form-group">
                        <Field disabled name="email" type="email" removeMargin={true} className="form-control" label="Email Address" placeholder="Enter here" component={TextInput} />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="file-upload bd-card profile">
                        <img src={ profilefileName?.imageUrl!=="" ? profilefileName?.imageUrl  : `${process.env.REACT_APP_API_BASE_URL}/api/auth/profileImage/${user?.id}`} className="img-fluid" alt="user-logo" />
                        <div className="position-relative">
                          <Field  name="profilepic" component={FileUpload} onFileSelected={(files) => onProfileUpload(files, setFieldValue)} />
                          <div className="text text-center">
                            <i className="fa-solid fa-upload"></i>
                            <p>Upload file type .pdf, .jpg, .png</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="content">
                <div className="btns text-end">
                  <a href="javascript:void(0)" onClick={() => navigate('/patient/list')} className="btn btn-theme btn-outline me-3">Cancel</a>
                  <button type='submit' className="btn btn-theme">Save Changes</button>
                </div>
              </div>
            </ div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default Profile;