import React, { useEffect, useRef, useState } from "react";
import { Outlet, NavLink, useNavigate } from "react-router-dom";
import AuthServiceProvider from "../../services/AuthService";
import { AuthService } from "../../api-services";
import DialogBox from "../../components/common/DialogBox";
import { Button } from "@mui/material";
const eastonLogo = require("../../assets/images/logo.png");
const userLogo = require("../../assets/images/user.png");

const Home = (props) => {

  const navigate = useNavigate();
  const loggedInUserDetailsSubscriber = useRef(null);
  const [profilePictureImageUrl, setProfilePictureImageUrl] = useState("");
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (window.location.pathname === "/admin") {
      navigate('/admin/list')
    }
    const loggedInUser = AuthServiceProvider.GetLoggedInUserData();
    setProfilePictureImageUrl(loggedInUser.profileImageUrl);
    registerLoggedInUserDetailsSubscriber();

    return () => {
      loggedInUserDetailsSubscriber.current.unsubscribe();
    }

  }, [])
  const logout = () => {
    try {
      AuthService.logout().then((res) => {
        localStorage.clear();
        navigate('/account/admin/login');
      })
    }
    catch (err) {

    }
  }


  const registerLoggedInUserDetailsSubscriber = () => {
    loggedInUserDetailsSubscriber.current = AuthServiceProvider.LoggedInUserDetails.subscribe(value => {
      const imageUrl = value.profileImageUrl + "?" + Math.random();
      setProfilePictureImageUrl(imageUrl);
    })
  }


  return <>

    <TopNavBar
      profileImageUrl={profilePictureImageUrl}
      open={open} setOpen={setOpen}
      logout={logout}
    />
    {/* Home Routes render section */}
    <Sidebar />
  </>
}

const TopNavBar = ({ profileImageUrl, open, setOpen, logout }) => <nav className="navbar navbar-expand-lg bg-body-tertiary top_sidebar">
  <div className="container-fluid">

    <NavLink className="nav-link" to="dashboard">
      <img alt="Company-Logo" src={eastonLogo} className="navbar-brand img-fluid" />
    </NavLink>

    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <Button variant="outlined" onClick={() => setOpen(true)}>
            Logout
          </Button>
          <DialogBox text="Logout" title={'Are you sure want to Logout'} open={open} handleSubmit={() => {
            logout()
          }} handleClose={() => setOpen(false)} />
        </li>
      </ul>
    </div>
  </div>
</nav>
const Sidebar = () => {
  return (
    <div style={{ overflow: "hidden" }} className="row m-0">
      <nav className="col-md-3 col-lg-2 d-md-block bg-light sidebar">
        <div className="position-sticky">
          <ul className="nav nav-pills flex-column mb-auto">
            <li className="nav-item  m-2">
              <NavLink to="/admin/dashboard" className="nav-link">
                Dashboard
              </NavLink>
            </li>
            <li className="nav-item m-2">
              <NavLink to="/admin/list" className="nav-link">
                List
              </NavLink>
            </li>
            <li className="nav-item m-2">
              <NavLink to="/admin/building" className="nav-link">
                Facility
              </NavLink>
            </li>
            {/* Add more sidebar items as needed */}
          </ul>
        </div>
      </nav>

      <main role="main" className="col-md-9 ml-sm-auto col-lg-10 sidebar_container">
        <Outlet />
      </main>
    </div>
  );
};



export default Home;