import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { SearchIcon } from '@react-pdf-viewer/search';

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import '@react-pdf-viewer/search/lib/styles/index.css';
import { SearchSidebar } from './SearchSidebar';
import { renderToolbar } from './ToolBarRender';

function SearchPdfText() {
    const [searchParams] = useSearchParams();
    const defaultLayoutPluginInstanceHighlight = defaultLayoutPlugin({
        renderToolbar,
        sidebarTabs: (defaultTabs) =>
            [
                {
                    content: (
                        <SearchSidebar
                            searchPluginInstance={
                                defaultLayoutPluginInstanceHighlight.toolbarPluginInstance.searchPluginInstance
                            }
                            decodedSearchText={ searchParams.get('searchText') != null ? atob(searchParams.get('searchText')) :""}
                        />
                    ),
                    icon: <SearchIcon />,
                    title: 'Search',
                },
            ].concat(defaultTabs),
    })

    const { toolbarPluginInstance } = defaultLayoutPluginInstanceHighlight;
    const { searchPluginInstance } = toolbarPluginInstance;
    const { highlight } = searchPluginInstance
    const [isDocumentLoaded, setDocumentLoaded] = useState(false);

    useEffect(() => {
        if (isDocumentLoaded) {
            if (searchParams.get('searchText') != null) {
                const decodedSearchText = atob(searchParams.get('searchText'));
                const keywords = [decodedSearchText, decodedSearchText.substring(1, 7), decodedSearchText.substring(1, 10)]
                keywords.forEach(keyword => {
                    highlight({
                        keyword: keyword
                    });
                });
            }
        }
    }, [isDocumentLoaded, searchParams])

    const handleDocumentLoad = () => setDocumentLoaded(true);




    return (
        <>
            {searchParams.get('pdfurl') != null && (
                <div
                    className="rpv-core__viewer"
                    style={{
                        border: '1px solid rgba(0, 0, 0, 0.3)',
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                    }}
                >
                    <div style={{
                        flex: 1,
                        overflow: 'hidden',
                    }}>
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                            <div style={{ height: "720px" }}>
                                <Viewer
                                    onDocumentLoad={handleDocumentLoad}
                                    fileUrl={searchParams.get('pdfurl')}
                                    plugins={[defaultLayoutPluginInstanceHighlight]}
                                />
                            </div>
                        </Worker>
                    </div>
                </div>
            )}
        </>
    );
}

export default SearchPdfText;



