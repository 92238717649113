import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AddPatientForm from './AddPatientForm';
import { Dialog } from "../../../components";
import Loader from '../../users/components/CircularLoader';

// console.log(pdfjs.version)

const AddPatientCaseForm = ({ addCases, onCancel, isModalOpen, setIsModalOpen, errorResponseMsg, createPatient, isSubmittingForm, patientExit }) => {
  const [HpFileReport, setHpFileReport] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  const onReportUpload = (file) => {
    let files = Array.prototype.slice.call(file);
    setHpFileReport((prev) => {
      let fileUploaded = [...prev];
      files?.forEach(element => {
        fileUploaded = [...fileUploaded, element]
      });
      return fileUploaded
    });
  }




  const onSubmitClick = (formData, setErrors) => {
    formData.hpReport = HpFileReport
    addCases(formData, setErrors)
    navigate('/patient/list', { state: { formData } })
  }

  const onToggleDialog = (isDialogOpen) => {
    setIsModalOpen(isDialogOpen)
  }
  return (
    <>
      {
        isSubmittingForm &&
        <Loader />

      }
      <div className="add-form p-0">
        <AddPatientForm
          onSubmit={onSubmitClick}
          onReportUpload={onReportUpload}
          onCancel={onCancel}
          editData={location?.state}
          patientExit={patientExit}
          HpFileReport={HpFileReport}
          setHpFileReport={setHpFileReport}
        />


      </div>

      {/* <Dialog
        isModalOpen={isModalOpen}
        onToggleDialog={(isDialogOpen) => onToggleDialog(isDialogOpen)}
        mode="semi-half"
      >

        <div className="row">

          <div className="col-md-12 mt-5">
              <h6 style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', marginBottom: '9spx' }}>{errorResponseMsg}</h6>
            <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', marginBottom: '9spx' }}>
            <button className="btn btn-outline-info btn-lg mt-4" style={{ width: '22rem' }} onClick={() => setIsModalOpen(false)}>No</button>
            <button className="btn btn-outline-success btn-lg mt-4" style={{ width: '22rem', marginLeft: '24px' }} onClick={createPatient}>Yes</button>
            </div>
          </div>
        </div>

      </Dialog> */}

    </>
  )



}


export default AddPatientCaseForm;