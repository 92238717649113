import axios from 'axios';
import AppConfig from '../AppConfig';
import Constants from '../Constants';
import { headers } from './index';
class AuthService {
    static Login = (payloadData) => {
        const url = `${AppConfig.ApiBaseUrl}${Constants.ApiUrl.auth.sigin}`;
        return axios.post(url, payloadData)
    }

    static adminLogin = (payloadData) => {
        const url = `${AppConfig.ApiBaseUrl}${Constants.ApiUrl.auth.adminsigin}`;
        return axios.post(url, payloadData)
    }


    static ForgotPassword = (payloadData) => {
        const url = `${AppConfig.ApiBaseUrl}${Constants.ApiUrl.auth.forgotPassword}`;
        return axios.post(url, payloadData)
    }

    static SendPdfandWordLink = (payloadData, generatePdfUrl, generatedWordLink) => {
        const url = `${AppConfig.ApiBaseUrl}${Constants.ApiUrl.auth.sendPdfandWordLink}`;
        return axios.post(url, payloadData, generatePdfUrl, generatedWordLink)
    }

    static ResetPassword = (payloadData) => {
        const url = `${AppConfig.ApiBaseUrl}${Constants.ApiUrl.auth.resetPassword}`;
        return axios.post(url, payloadData)
    }
    static checkresetToken = (token) => {
        const url = `${AppConfig.ApiBaseUrl}${Constants.ApiUrl.auth.checkresetToken}?token=${token}`;
        return axios.get(url).then(resp => resp.data);
    }

    static logout = ()=> {
        const url = `${AppConfig.ApiBaseUrl}${Constants.ApiUrl.auth.logout}`;
        return axios.get(url, {...headers})
    }

    static createNewPassword = (payloadData) => {
        const url = `${AppConfig.ApiBaseUrl}${Constants.ApiUrl.auth.createNewPassword}`;
        return axios.post(url, payloadData)
    }

}

export default AuthService;