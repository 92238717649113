import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import WarningIcon from '@mui/icons-material/Warning';
import { IconButton } from '@mui/material';
export default function DisclamerDialog({ title, open, handleClose, handleAgreeOnclick }) {


    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" sx={{ display: "flex", justifyContent: "center", color: "black", fontWeight: "600" }}>
                    <IconButton sx={{ top: "-5.5px" }}><WarningIcon sx={{ borderColor: "black", color: "red" }} /></IconButton> Disclaimer
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" sx={{ color: "red" }}>
                        {title}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button sx={{ color: "black", fontWeight: "600" }} onClick={handleClose}>Disagree</Button>
                    <Button sx={{ color: "black", fontWeight: "600" }} onClick={handleAgreeOnclick} autoFocus>
                        Agree
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
