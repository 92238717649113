import { useEffect, useState } from 'react'
import { Field, Formik, Form } from 'formik'
import * as Yup from 'yup'
import { TextInput } from '../../components'
import { Link, useNavigate, useParams } from 'react-router-dom'
import LoggedInUserService from "../../services/AuthService";
import { AuthService } from '../../api-services'
import Loader from '../../components/common/Loader'
const companyLogo = require("../../assets/images/logo.png")
export const CreateNewPassword = ({ onSubmitCreateNewPassowrd, hideElement }) => {
    const navigate = useNavigate();
    let { token } = useParams();
    const [checkResetToken, setcheckResetToken] = useState(null)
    const [loading, setloading] = useState(false)
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setshowConfirmPassword] = useState(false)
    useEffect(() => {
        if (hideElement != null && hideElement) {
            setcheckResetToken(0)
            setloading(true)
            AuthService.checkresetToken(token).then((res) => {
                setcheckResetToken(res?.data?.success)
                setloading(false)
            }).catch(err => {
                setloading(false)
                setcheckResetToken(err?.response?.data.success)

            })
        }
    }, [hideElement, token])

    const validationSchema = Yup.object().shape({
        newPassword: Yup.string().required('Password is required').min(8, 'Password must be at least 8 characters long'),
        confirmPassword: Yup.string().required('Confirm password is required').oneOf([Yup.ref('newPassword')], 'Passwords must match')
    })

    const onSubmit = (values, resetForm) => {
        onSubmitCreateNewPassowrd(values, resetForm)

    }

    if (hideElement != null && hideElement && loading)
        return <Loader />

    if (checkResetToken != null && !checkResetToken)
        return <RenderLinkExpired />
    const encryptResetTokenPayload = () => {
        return LoggedInUserService.checkloggedInUserFirstTime(token)
    }

    function getSubdomain(hostname) {
        try {
            const parts = hostname.split('.');
            if (parts?.length > 1) {
                return parts[0]
            }
            return '';
        } catch (error) {
            console.error('Error extracting subdomain:', error);
            return '';
        }
    }




    return (
        <>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <div className={hideElement != null && hideElement ? "card" : ""}>
                            <div className={hideElement != null && hideElement ? "card-body" : ""}>
                                <h2 className="text-center">Create Password</h2>
                                {hideElement != null && hideElement && <img src={companyLogo} className="img-fluid" alt="company-logo" />}
                                <Formik
                                    initialValues={{
                                        newPassword: '',
                                        confirmPassword: '',
                                    }}
                                    enableReinitialize={true}
                                    validationSchema={validationSchema}
                                    onSubmit={(values, { setErrors, resetForm }) => {
                                        const { hostname } = window.location;
                                        let subdomain = getSubdomain(hostname)
                                        values.subdomain = subdomain
                                        if (hideElement != null && hideElement) {
                                            values.encryptPayload = encryptResetTokenPayload()
                                        }
                                        onSubmit(values, resetForm)
                                    }}
                                >
                                    {() => (
                                        <Form>
                                            <div style={{ textAlign: "center" }} className="fields">
                                                <div className="form-group" style={{ position : "relative"}}>
                                                    <Field name="newPassword" removeMargin={true} shouldDollarRender={true}
                                                        shouldUserRenderIcon={<> <span style={{
                                                            width: "2.5rem",
                                                            outline: "none",
                                                            height: "48px",
                                                            border: "none",
                                                            position: "absolute"
                                                        }}
                                                            className="input-group-text"><i className="fa-solid fa-key"></i></span></>} className="form-control"  placeholder="Enter Password" type={ showNewPassword ? "text" : "password"} component={TextInput} />
                                                    <span
                                                        onClick={() => setShowNewPassword(!showNewPassword)}
                                                        style={{
                                                            position: 'absolute',
                                                            right: '10px',
                                                            top: '27px',
                                                            transform: 'translateY(-50%)',
                                                            cursor: 'pointer'
                                                        }}
                                                    >
                                                        <i className={showNewPassword ? "fa fa-eye" : "fa fa-eye-slash"}></i>
                                                    </span>
                                                </div>
                                                <div className="form-group" style={{ position : "relative"}}>
                                                    <Field name="confirmPassword" removeMargin={true} shouldDollarRender={true}
                                                        type={showConfirmPassword ? "text" : "password"}
                                                        shouldUserRenderIcon={<> <span style={{
                                                            width: "2.5rem",
                                                            outline: "none",
                                                            height: "48px",
                                                            border: "none",
                                                            position: "absolute"

                                                        }} className="input-group-text"><i className="fa-solid fa-key"></i></span></>} className="form-control"  placeholder="Enter Confirm Password" component={TextInput} />
                                                    <span
                                                        onClick={() => setshowConfirmPassword(!showConfirmPassword)}
                                                        style={{
                                                            position: 'absolute',
                                                            right: '10px',
                                                            top: '27px',
                                                            transform: 'translateY(-50%)',
                                                            cursor: 'pointer'
                                                        }}
                                                    >
                                                        <i className={showConfirmPassword ? "fa fa-eye" : "fa fa-eye-slash"}></i>
                                                    </span>

                                                </div>
                                            </div>
                                            <button type='submit' className={`btn-theme ${hideElement != null && hideElement ? "btn-login" : "btn-create_password"}`}>Submit</button>
                                            {hideElement == null && !hideElement ? <></> : <button onClick={(e) => { e.preventDefault(); navigate('/account/login') }} type="button" className="btn btn-link">Back</button>}
                                        </Form>)}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}


const RenderLinkExpired = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-lg-6 offset-lg-3 col-12">
                    <div className="success-inner">
                        <h1>Oops, this link is expired</h1>
                        <p>This URL is not valid anymore.</p>
                        <Link className="text-theme" to="/account/login"><i className="fa-solid fa-arrow-left me-2"></i> Back to Login</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}