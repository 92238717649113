import React from 'react'
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
const NursingComponent = ({ nursingComponentResult, setNtaScoreResult, NtaScoreResult, handlePdfUrlSearch }) => {
    
    return (
        <div className="listing">
            <div class="title d-flex justify-content-between align-items-center">
                <div>
                    <span>List of all Nursing Component</span>
                    <div style={{ display: 'flex', alignItems: "center", gap: "10px" }}>
                        <p className="info" style={{ fontWeight: 'bold' }}>Nursing Categories: <span style={{ fontWeight: 'normal', color: "#666" }}> {NtaScoreResult?.selectedNursingCategory}</span> </p>
                    </div>

                    {
                        nursingComponentResult?.length > 0 &&
                        <div style={{ display: 'flex', alignItems: "center", gap: "10px" }}>
                            <FormControl fullWidth className="select_des">
                                <InputLabel id="demo-simple-select-label">Select Category</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Select Category"
                                    value={NtaScoreResult?.selectedNursingCategory}
                                    onChange={(e) => {
                                        setNtaScoreResult((prev) => {
                                            return {
                                                ...prev,
                                                selectedNursingCategory: e.target.value
                                            }
                                        })
                                    }}
                                >
                                    {nursingComponentResult?.map((obj, index) => (
                                        <MenuItem key={index} value={obj?.Category}>{obj?.Category}</MenuItem>
                                    ))
                                    }
                                </Select>
                            </FormControl>

                        </div>
                    }

                </div>
            </div>

            <div id="myTable_wrapper" class="dataTables_wrapper no-footer">
                <table id="myTable" class="display dataTable no-footer" aria-describedby="myTable_info">
                    <thead>
                        <tr>
                            <th className={`sorting`} tabindex="0" aria-controls="myTable" rowspan="1" colspan="1" style={{ width: "310.875px" }}>Category</th>
                            <th className={`sorting`} tabindex="0" aria-controls="myTable" rowspan="1" colspan="1" style={{ width: "310.038px" }}>Qualification</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            nursingComponentResult?.length > 0 ?
                                nursingComponentResult?.map((obj, i) => (
                                    <tr class="odd" key={i}>
                                        <td>{obj?.Category}</td>
                                        <td>
                                            {obj && obj.Qualifications && obj.Qualifications.map((item, index) => (
                                                <React.Fragment key={index}>
                                                    <a
                                                       className="link-with-hover"
                                                        onClick={()=>handlePdfUrlSearch(item?.fileName ,item.QualificationsKeyword)}
                                                        style={{ cursor: "pointer" }}
                                                        href="javascript:void(0)"
                                                        rel="noopener noreferrer"
                                                        title={item?.Qualifications}
                                                    >
                                                        {item && item.QualificationsKeyword && (
                                                            <React.Fragment>
                                                                {item.QualificationsKeyword}
                                                                {index < obj.Qualifications.length - 1 && " , "}
                                                            </React.Fragment>
                                                        )}
                                                    </a>
                                                </React.Fragment>
                                            ))}
                                        </td>

                                    </tr>
                                ))
                                :
                                <td colSpan={12} style={{ textAlign: "center" }}>No Data</td>

                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default NursingComponent