import * as React from 'react';
import { TextField, Stack, Autocomplete } from '@mui/material';
import { ParseAndFormatUtcDate } from '../../Utils';
export default function SearchBox({ setsearchText, searchText, searcData, setpatientExit, setpatientSearchList, loading }) {
    React.useEffect(() => {
        const element = document.querySelector('.MuiAutocomplete-endAdornment');
        let clearButton;
        if (element) {
            element.id = "clear_icon"
             clearButton = document.querySelector('#clear_icon button');
            if (clearButton) {
                clearButton.addEventListener('click', handleClick);
            } else {
                console.log('Button inside #clear_icon not found');
            }
        }

        else {
            console.log('Element with id "free-solo-demo" not found');
        }
        return () => {
            if (clearButton) {
              clearButton.removeEventListener('click', handleClick);
            }
          };
    }, [handleClick, searcData])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    function handleClick() {
        setpatientSearchList([])
        setsearchText('')
    }
    const handleOnChangeSelect = (event, selectedOption) => {
        const { value } = selectedOption != null ? selectedOption : {}
        let params = {
            firstName: value?.firstName,
            lastName: value?.lastName,
            dob: value?.dob
        }
        setpatientExit({ ...params })
        setpatientSearchList(null)
        setsearchText('')
    };

    return (
        <Stack spacing={2} sx={{ width: 300, marginRight: "20px" }}>
            <Autocomplete
                id="free-solo-demo"
                freeSolo
                options={searcData!=null ? searcData?.map((item) => ({ label: `${item?.firstName} ${item?.lastName}`  , value: item })) :[]}
                onChange={handleOnChangeSelect}
                renderInput={(params) => <TextField {...params} onChange={(e) => {
                    if (e.target.value < 1) {
                        setpatientSearchList(null);
                        setsearchText('')
                    }
                    else if(searcData?.length < 1){
                        setpatientSearchList(null);
                    }
                     else { setsearchText(e.target.value) }
                }} label="Search" />}
            />
            {loading && searchText.length > 1 ? <p className='text-center'>Loading...</p> :  searchText.length > 1 &&  searcData!=null &&  searcData?.length < 1 ? <p className='text-center'>No data available</p> : ""}
        </Stack>
    );
}
