import axios from 'axios';
import AppConfig from '../AppConfig';
import Constants from '../Constants';

class SuperAdminAuthService {
    static Login = (payloadData) => {
        const url = `${AppConfig.ApiBaseUrl}${Constants.ApiUrl.adminauth.sigin}`;
        return axios.post(url, payloadData)
    }
}

export default SuperAdminAuthService;