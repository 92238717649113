import moment from 'moment';

const GenerateUUID = () => {
    let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0;
        const v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });

    return uuid;
}

const ParseAndFormatUtcDateTime = (dateString) => {
    return moment(new Date(dateString), 'MMM DD, YYYY h:mm a').format('YYYY-MM-DD HH:mm:ss');
}

const ParseAndFormatUtcDate = (dateString) => {
    return `${moment(dateString).format('YYYY-MM-DD')}`;
}

export { GenerateUUID, ParseAndFormatUtcDateTime ,ParseAndFormatUtcDate };