import React, { Fragment, useEffect, useRef, useState } from "react";
import {  useSearchParams } from 'react-router-dom';
import { Dialog, RenderIf } from "../../components";
import DotLoader from "react-spinners/BeatLoader";
import { PatientService } from "../../api-services";
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import InfoIcon from '@mui/icons-material/Info';
import { Stack, Typography, IconButton, FormControl, Select, MenuItem, InputLabel, Button } from "@mui/material";
import NursingComponent from "./NursingComponent";
import Loader from "../users/components/CircularLoader";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import LoggedInUserService from "../../services/AuthService";
const companyLogo = require("../../assets/images/logo.png")

const PdfPreview = () => {
    const [searchParams] = useSearchParams();
    const [generatePdfUrl, setGeneraterPdfUrl] = useState(null);
    const [NtaScoreResult, setNtaScoreResult] = useState({ hpReportIC10Result: null, totalScoreICD10:  null, hpReportMedicationResult: null, totalScoreMedication: 0, nursingComponentResult: null, selectedNursingCategory: null , patientInfo : {} })
    const [isModalOpen, setIsModalOpen] = useState(false);
    const canvasRef = useRef(null);
    const [isGeneratingPdf, setIsGeneratingPdf] = useState('');
    const [genereatedPDFLink, setGeneratedPDFLink] = useState('');
    const [generatedWordLink, setGeneratedWordLink] = useState('');
    const [toogleDownload, settoogleDownload] = useState(true);
    const [MasterMedicationList, setMasterMedicationList] = useState([])
    const [MasterNTAICD10List, setMasterNTAICD10List] = useState([]);
    const [pdfUrl, setpdfUrl] = useState(null);
    const [loading, setLoading] = useState(false);
    const listingRef = useRef(null);
    const loggedInUserDetails = LoggedInUserService.GetLoggedInUserData()
    const Facility = localStorage.getItem("Facility")?.replace(/['"]+/g, '')

    useEffect(() => {
        setLoading(true)
        if (searchParams.get('patientId') != null) {
        const token = searchParams.get('token')!=null ?  atob(searchParams.get('token')) : null
        const patientId = atob(searchParams.get('patientId'));
        PatientService.GetSinglePatient(patientId , token).then((resp) => {
            let changeIcdCodesRes = resp?.data?.result?.icdCodesRecords?.map((item) => ({ ...item, MDS_ITEM_Edit: item?.MdsItem?.MDS_ITEM, MDS_ITEM__POINT_Edit: item?.MdsItem?.Points }))
            setNtaScoreResult((prev) => {
                return {
                    ...prev,
                    patientInfo :JSON.parse(JSON.stringify(resp?.data?.result?.icdCodesRecords?.map((item) => ({ ...item, MDS_ITEM_Edit: item?.MdsItem?.MDS_ITEM, MDS_ITEM__POINT_Edit: item?.MdsItem?.Points })))),
                    hpReportIC10Result: changeIcdCodesRes,
                    totalScoreICD10: calculateNtaScore(changeIcdCodesRes),
                    patientInfoData : resp?.data,
                    hpReportMedicationResult: resp?.data?.result?.medicationRecordData, totalScoreMedication: 0, nursingComponentResult: resp?.data?.result?.nursingComponent, selectedNursingCategory: resp?.data?.result?.selectedNursingCategory
                }
            })
            setLoading(false)
        }).catch(err => {

        })
        PatientService.GetMedicationMasterData().then((res) => {
            setMasterMedicationList(res?.data);
        }).catch(err => {

        })
        getNtaICD_10_MasterData();
    }

    }, [searchParams])

    console.log(NtaScoreResult.patientInfo)


    const getNtaICD_10_MasterData = () => {
        PatientService.GetNTAICD_10_MasterData().then((res) => {
            setMasterNTAICD10List(res?.data);
        }).catch(err => {

        })

    }




    const generatedPdf = () => {
        setIsGeneratingPdf(true);
        const { hpReportIC10Result, hpReportMedicationResult, totalScoreMedication, totalScoreICD10, nursingComponentResult, selectedNursingCategory } = NtaScoreResult
        PatientService.GenerateCaseDocsAndGetDocsUrls({ icdCodesRes: hpReportIC10Result, medicationRecordData: hpReportMedicationResult, totalScoreMedication, totalScoreICD10, nursingRecordData: nursingComponentResult, selectedCategory: selectedNursingCategory })
            .then((resp) => {
                setGeneraterPdfUrl(resp.data.pdfUrl);
                setGeneratedPDFLink(resp.data.pdfUrl)
                setGeneratedWordLink(resp.data.wordUrl)
                setIsGeneratingPdf(false);
                setIsModalOpen(true)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const onToggleDialog = (isDialogOpen) => {
        setIsModalOpen(isDialogOpen)
    }

    const onDownloadPDFfile = () => {
        fetch(generatePdfUrl)
            .then(response => response.blob())
            .then(blob => {
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = "fileName.pdf";
                link.click();
                window.URL.revokeObjectURL(link.href);
            })
            .catch(error => {
                console.error('Error downloading PDF:', error);
            });
    }

    function calculateNtaScore(data) {
        const uniqueSubstrings = {};
        data?.forEach((item) => {
            if (item?.mergeDiagnosis?.length > 0) {
                item?.mergeDiagnosis?.sort((a, b) => b?.Points - a?.Points).forEach((diagnosis) => {
                    const icdSubstring = diagnosis?.icdcode?.substring(0, 3);
                    if (!uniqueSubstrings[icdSubstring]) {
                        uniqueSubstrings[icdSubstring] = {
                            Points: diagnosis.Points || 0,
                            MDS_ITEM__POINT_Edit: diagnosis?.MdsItem?.Points || 0,
                        };
                    }
                })
            }
            else {
                const icdSubstring = item?.icdcode?.substring(0, 3);
                if (!uniqueSubstrings[icdSubstring]) {
                    uniqueSubstrings[icdSubstring] = {
                        Points: item.Points || 0,
                        MDS_ITEM__POINT_Edit: item?.MDS_ITEM__POINT_Edit || 0,
                    };
                }
            }

        });
        // Calculate the total score based on unique substrings
        const totalScore = Object.values(uniqueSubstrings).reduce(
            (accumulator, substringScores) => {
                const { Points, MDS_ITEM__POINT_Edit } = substringScores;
                return accumulator + Points + MDS_ITEM__POINT_Edit;
            },
            0
        );
        return totalScore;
    }

    function calculateNtaMedicationScore(data) {
        const totalScore = data?.reduce((accumulator, item) => {
            const points = item?.MDS_Point || 0;
            return accumulator + points;
        }, 0);
        return totalScore;
    }

    const UpdateNta = async (e) => {
        e.preventDefault();
        const { patientInfoData} = NtaScoreResult
        PatientService.updateNta({ id: patientInfoData?._id, icdCodesRecords: NtaScoreResult?.hpReportIC10Result, medicationRecordData: NtaScoreResult?.hpReportMedicationResult, selectedNursingCategory: NtaScoreResult?.selectedNursingCategory })
            .then((resp) => {
                settoogleDownload(false);
            })
            .catch((err) => {
                settoogleDownload(true)
                console.log(err)
            })
    }

    const renderMedicationInfo = (type) => {
        let findMedicationInfo = MasterMedicationList?.find((medication) => medication?.Category === type)
        return (
            findMedicationInfo !== undefined && (
                <>
                    <Stack direction={"row"}>
                        <Typography sx={{ color: "#440E66", fontSize: "15px", fontWeight: "500", textDecoration: "underline", paddingBottom: "5px" }} >Nutritional Approaches :</Typography>
                        <Typography pl={2} sx={{ fontSize: "15px" }}>{findMedicationInfo?.Category}</Typography>
                    </Stack>

                    <Stack direction={"row"}>
                        <Typography sx={{ color: "#440E66", width: "auto", fontSize: "15px", fontWeight: "500", textDecoration: "underline", paddingBottom: "5px" }} >MDS ITEM:</Typography>
                        <Typography pl={2} sx={{ fontSize: "15px", wordBreak: "break-all", textAlign: "left" }}>{findMedicationInfo?.MDS_ITEM}</Typography>
                    </Stack>
                </>
            )

        )
    }

    const checkMedicationOnHover = (type) => {
        let findMedicationInfo = MasterMedicationList?.find((medication) => medication?.Category === type)
        if (findMedicationInfo !== undefined) {
            return true;
        }
        else {
            return false
        }
    }

    const handleChangeMedication = (i, obj, value) => {
        setNtaScoreResult((prev) => {
            let array = [...NtaScoreResult?.hpReportMedicationResult]
            let findMedicationInfo = MasterMedicationList?.find((medication) => medication?.Category === obj?.IVType && medication?.MDS_ITEM?.split(',')?.includes(value))
            if (findMedicationInfo !== undefined) {
                array[i].MDS_Point = findMedicationInfo.Points
                array[i].MDS_Desc = value
            }
            else {
                array[i].MDS_Point = 0
                array[i].MDS_Desc = value
            }
            return { ...prev, hpReportMedicationResult: array, totalScoreMedication: calculateNtaMedicationScore(array) }
        })
    }


    const handleResetSelectedOption = (e, type, parentIndex, childindex) => {
        e.preventDefault();
        const { patientInfo} = NtaScoreResult
        setNtaScoreResult((prev) => {
            let array = [...prev.hpReportIC10Result]
            if (type === "singleDiagnisis") {
                array[parentIndex].description = patientInfo[parentIndex].description
                array[parentIndex].Points = 0
                array[parentIndex].icdcode = patientInfo[parentIndex].icdcode
            }
            else {
                array[parentIndex]['mergeDiagnosis'][childindex].description = patientInfo[parentIndex]['mergeDiagnosis'][childindex].description
                array[parentIndex]['mergeDiagnosis'][childindex].Points = 0
                array[parentIndex]['mergeDiagnosis'][childindex].icdcode = patientInfo[parentIndex]['mergeDiagnosis'][childindex].icdcode
            }

            return {
                ...prev,
                hpReportIC10Result: array
            }
        })

    }


    const handleMedicationSearchOnPd = (searchText) => {
        const regex = /\((.*?)\)/;
        const match = regex.exec(searchText);
        let textSearch = "";
        if (match && match?.length > 2) {
            textSearch = match[1];
        }
        else {
            textSearch = searchText
        }

        return textSearch
    }


    const handlePdfUrlSearch = (file, description) => {
        const {  OCRfile : multipdfUrl , fileName  } = NtaScoreResult.patientInfoData
        let pdfUrlArray = multipdfUrl
        let pdf = findPdfByUrl(file , multipdfUrl)
        const url = searchParams.get('token')!=null ?  'admin' : 'patient'
        if (pdf!==undefined && pdfUrlArray != null && Array.isArray(pdfUrlArray) && pdfUrlArray?.length > 0 && file?.fileName) {
             pdf = findPdfByUrl(file , pdfUrlArray)
            if (pdf) {
                window.open(`/${url}/searchWord?pdfurl=${(pdf?.filePath)}&searchText=${btoa(description)}`)

            }
        }
        else if(fileName != null && Array.isArray(fileName) && fileName?.length > 0 && file?.fileName) {
            pdf = findPdfByUrl(file , fileName)
            window.open(`/${url}/searchWord?pdfurl=${(encodeURIComponent(pdf?.filePath))}&searchText=${btoa(description)}`)
        }
    }


    const findPdfByUrl = (file , multipdfUrl) => {
        const { fileName } = file
        const pdf = multipdfUrl?.find((item) => item?.fileName === fileName);
        return pdf;
    };

    const downloadCurrentPageAsPDF = () => {
        const input = listingRef.current;
        const logo = new Image();
  
        logo.src = companyLogo;
        
        html2canvas(input, {
          ignoreElements: (element) => element.classList.contains("btn-theme") ||
          element.tagName === "INPUT" ||
          element.tagName === "TEXTAREA" ||
          element.tagName === "SELECT" ||
          element.classList.contains("select_des"),
          scale: 1,
        }).then((canvas) => {
          const imgData = canvas.toDataURL("image/png");
          const pdf = new jsPDF("p", "mm", "a4");
          const imgProps = pdf.getImageProperties(imgData);
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
  
          let heightLeft = pdfHeight;
          let position = 0;
          let imgHeight = (canvas.height * pdfWidth) / canvas.width;
  
          pdf.addImage(imgData, "PNG", 0, position, pdfWidth, imgHeight);
          pdf.addImage(logo, "PNG", pdfWidth - 60, 10, 50, 10);
          heightLeft -= pdf.internal.pageSize.height;
  
          while (heightLeft >= 0) {
            position = heightLeft - imgHeight;
            pdf.addPage();
            pdf.addImage(imgData, "PNG", 0, position, pdfWidth, imgHeight);
            heightLeft -= pdf.internal.pageSize.height;
          }
  
          pdf.save("NTACal.pdf");
        });
      };

    const handleshowSection = (section) => {

        const sectionArray = ["Chest X-ray Reports", "CT Scan Reports", "Laboratory Results", "Microbiology/Lab Tests", "Radiology", "CT Scan Reports"]
        let showText = null
        if (sectionArray.some(sec => section.toLowerCase().includes(sec.toLowerCase()))) {
            showText = "Please verify with MD, qualify for NTA";
        }


        return (
            <>
                {
                    showText !== null &&
                    (
                        <Tooltip
                            TransitionComponent={Zoom}
                            TransitionProps={{ timeout: 600 }}
                            placement="right-start"
                            arrow
                            title={
                                <span
                                    style={{
                                        padding: '4px',
                                        fontSize: '13px',
                                        backgroundColor: '#efe6f4',
                                        width: "auto",
                                        color: "#423a3afa",
                                        display: "block"
                                    }}
                                >
                                    <Stack direction={"row"}>
                                        <Typography sx={{ color: "#de2020", fontSize: "15px", fontWeight: "500", textDecoration: "underline", paddingBottom: "5px" }} >{showText}</Typography>
                                    </Stack>
                                </span>
                            }
                        >
                            <IconButton sx={{ color: "#de2020" }} >
                                <InfoIcon />
                            </IconButton>
                        </Tooltip>
                    )

                }
            </>

        )

    }


    if (loading)
        return <Loader />
    return (
        <>
            <RenderIf shouldRender={generatePdfUrl}>

                <div ref={canvasRef}></div>

                <DotLoader

                    color={'#00a6ff'}
                    loading={isGeneratingPdf}
                    size={50}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
            </RenderIf>
            <div ref={listingRef}>
            <div className="listing">

                <div class="title d-flex justify-content-between align-items-center">
                    <div>
                        <span>List of all NTA component</span>
                        <p className="info bold-text">{(Facility !== undefined && `Facility Name: ${Facility}`) || (loggedInUserDetails?.hospitalName && `Facility Name: ${loggedInUserDetails.hospitalName}`) || ""}
                        </p>
                        <p className="info bold-text">Name: {NtaScoreResult?.patientInfoData?.firstName} {NtaScoreResult?.patientInfoData?.lastName}</p>
                        <p className="info bold-text">Total Score: {NtaScoreResult?.totalScoreICD10}</p>
                    </div>
                    <div >
                        {/* <button style={{ marginRight: "20px" }} class="btn btn-theme" onClick={getOCRpdfUrl}> Search</button> */}
                        <button style={{ marginRight: "20px" }} class="btn btn-theme" onClick={downloadCurrentPageAsPDF}> Download PDF</button>
                        <button style={{ marginRight: "20px" }} class="btn btn-theme" onClick={UpdateNta}> Verify and Save</button>
                        {/* <button class="btn btn-theme" disabled={toogleDownload} onClick={() => generatedPdf()}> Download</button> */}
                    </div>
                </div>

                <div id="myTable_wrapper" class="dataTables_wrapper no-footer">
                    <table id="myTable" class="display dataTable no-footer" aria-describedby="myTable_info">
                        <thead>
                            <tr>
                                <th className={`sorting`} tabindex="0" aria-controls="myTable" rowspan="1" colspan="1" style={{ width: "250.875px" }}>ICD-10-CM Code</th>
                                <th className={`sorting`} tabindex="0" aria-controls="myTable" rowspan="1" colspan="w" style={{ width: "350.038px" }}>ICD-10-CM Code Description</th>
                                <th className={`sorting`} tabindex="0" aria-controls="myTable" rowspan="1" colspan="1" style={{ width: "250.875px" }} >MDS Info</th>
                                <th className={`sorting`} tabindex="0" aria-controls="myTable" rowspan="1" colspan="1" style={{ width: "250.875px" }} >NTA Points</th>
                                {/* <th className={`sorting`} tabindex="0" aria-controls="myTable" rowspan="1" colspan="1" style={{ width: "250.475px" }} >Enter MDS ITEM </th> */}
                                <th className={`sorting`} tabindex="0" aria-controls="myTable" rowspan="1" colspan="1" style={{ width: "250.875px" }} >MDS Points</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                NtaScoreResult?.hpReportIC10Result?.map((obj, i) => (
                                    <>
                                        {
                                            obj?.mergeDiagnosis?.length > 0 ?
                                                <>
                                                    {
                                                        obj?.mergeDiagnosis?.map((diagnosis, diagnosisIndex, diagnosisArr) => (
                                                            <tr className={diagnosisIndex > 0 ? `merge` : ""}>
                                                                <Fragment key={diagnosisIndex}>
                                                                    <td>{diagnosis.icdcode}</td>
                                                                    {
                                                                        diagnosis?.NTA_ICD_COD_Desc && diagnosis?.NTA_ICD_COD_Desc?.length > 0 ?
                                                                            <td>
                                                                                <div className="input-field-input">
                                                                                    <div>
                                                                                        <a
                                                                                            onClick={() => handlePdfUrlSearch(diagnosis?.fileName, diagnosis?.description)}
                                                                                            href="javascript:void(0)"
                                                                                            style={{ cursor: "pointer" }}
                                                                                            rel="noopener noreferrer"
                                                                                        >
                                                                                            {diagnosis?.description}
                                                                                        </a>
                                                                                        {diagnosis?.section && handleshowSection(diagnosis?.section)}
                                                                                    </div>
                                                                                    <FormControl fullWidth className="select_des">
                                                                                        <InputLabel id="demo-simple-select-label">Select Description</InputLabel>
                                                                                        <Select
                                                                                            labelId="demo-simple-select-label"
                                                                                            id="demo-simple-select"
                                                                                            label="Select Description"
                                                                                            onChange={(e) => {
                                                                                                setNtaScoreResult((prev) => {
                                                                                                    let array = [...prev.hpReportIC10Result]
                                                                                                    for (var j of array) {
                                                                                                        if (j?.mergeDiagnosis?.length > 0) {
                                                                                                            for (var t of j?.mergeDiagnosis) {
                                                                                                                if (t?.NTA_ICD_COD_Desc !== undefined) {
                                                                                                                    for (var k of t?.NTA_ICD_COD_Desc) {
                                                                                                                        if (k?._id === e.target.value) {
                                                                                                                            let checkPoints = MasterNTAICD10List?.find((item) => item?.Category === k?.ComorbidityDes)
                                                                                                                            array[i].mergeDiagnosis[diagnosisIndex].description = k?.ICD_Code_Desc;
                                                                                                                            array[i].mergeDiagnosis[diagnosisIndex].icdcode = k?.ICD_Code;
                                                                                                                            array[i].mergeDiagnosis[diagnosisIndex].Points = checkPoints !== undefined ? checkPoints?.Points : 0
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                    return { ...prev, hpReportIC10Result: array, totalScoreICD10: calculateNtaScore(array) };
                                                                                                })
                                                                                            }}
                                                                                        >
                                                                                            {diagnosis?.NTA_ICD_COD_Desc.map((option, index) => (
                                                                                                <MenuItem key={index} value={option._id}>{option.ICD_Code_Desc}</MenuItem>
                                                                                            ))
                                                                                            }
                                                                                            <MenuItem sx={{ padding: 0 }}>
                                                                                                <Button onClick={(e) => handleResetSelectedOption(e, "mergeDiagnisis", i, diagnosisIndex)} variant="#86BD3B" color="secondary" sx={{
                                                                                                    borderRadius: "0 !important", width: "100%",
                                                                                                    mr: 0,
                                                                                                    cursor: "pointer"
                                                                                                }}>
                                                                                                    <RestartAltIcon sx={{ color: "#86BD3B" }} />
                                                                                                    <IconButton sx={{ paddingLeft: "0px", fontSize: "19px" }} />Reset
                                                                                                </Button>
                                                                                            </MenuItem>
                                                                                        </Select>
                                                                                    </FormControl>
                                                                                </div>
                                                                            </td>
                                                                            : <td>

                                                                                <a
                                                                                    style={{ cursor: "pointer" }}
                                                                                    onClick={() => handlePdfUrlSearch(diagnosis?.fileName, diagnosis?.description)}
                                                                                    href="javascript:void(0)"
                                                                                    rel="noopener noreferrer"
                                                                                > {diagnosis?.description}</a>
                                                                                {diagnosis?.section && handleshowSection(diagnosis?.section)}
                                                                            </td>
                                                                    }
                                                                    {diagnosis?.MdsItem ?
                                                                        <td>
                                                                            <div className="tooltip_med" style={{ paddingLeft: "16px" }}>
                                                                                <Tooltip
                                                                                    TransitionComponent={Zoom}
                                                                                    TransitionProps={{ timeout: 600 }}
                                                                                    placement="right-start"
                                                                                    arrow
                                                                                    title={
                                                                                        <span
                                                                                            style={{
                                                                                                padding: '4px',
                                                                                                fontSize: '13px',
                                                                                                backgroundColor: '#efe6f4',
                                                                                                width: "auto",
                                                                                                color: "#423a3afa",
                                                                                                display: "block"
                                                                                            }}
                                                                                        >
                                                                                            <Stack direction={"row"}>
                                                                                                <Typography sx={{ color: "#440E66", fontSize: "15px", fontWeight: "500", textDecoration: "underline", paddingBottom: "5px" }} >MDS ITEM :</Typography>
                                                                                                <Typography pl={1} sx={{ fontSize: "15px" }}>{diagnosis?.MdsItem?.MDS_ITEM}</Typography>
                                                                                            </Stack>
                                                                                            <Stack direction={"row"}>
                                                                                                <Typography sx={{ color: "#440E66", fontSize: "15px", fontWeight: "500", textDecoration: "underline", paddingBottom: "5px" }} >MDS Items:</Typography>
                                                                                                <Typography pl={1} sx={{ fontSize: "15px" }}>{diagnosis?.MdsItem?.Category}</Typography>
                                                                                            </Stack>
                                                                                        </span>
                                                                                    }
                                                                                >
                                                                                    <IconButton >
                                                                                        <InfoIcon />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </div>
                                                                        </td>

                                                                        : <td> - </td>}


                                                                    {/* <td> {diagnosisIndex === diagnosisArr?.length - 1 && diagnosisArr?.[0]?.Points}</td> */}
                                                                    <td>{diagnosis?.Points}</td>
                                                                    <td>
                                                                        {
                                                                            diagnosis?.MdsItem ?
                                                                                diagnosis?.MdsItem?.Points : 0
                                                                        }
                                                                    </td>
                                                                </Fragment>
                                                            </tr>
                                                        )
                                                        )
                                                    }
                                                </>


                                                :
                                                <>
                                                    <tr className="odd">
                                                        <td>{obj?.icdcode}</td>
                                                        {
                                                            obj?.NTA_ICD_COD_Desc && obj?.NTA_ICD_COD_Desc?.length > 0 ?
                                                                <td>
                                                                    <div className="input-field-input">
                                                                        <div>
                                                                            <a
                                                                                style={{ cursor: "pointer" }}
                                                                                onClick={() => handlePdfUrlSearch(obj?.fileName, obj?.description)}
                                                                                href="javascript:void(0)"
                                                                                rel="noopener noreferrer"
                                                                            > {obj?.description}</a>
                                                                            {obj?.section && handleshowSection(obj?.section)}
                                                                        </div>
                                                                        <FormControl fullWidth className="select_des">
                                                                            <InputLabel id="demo-simple-select-label">Select Description</InputLabel>
                                                                            <Select
                                                                                labelId="demo-simple-select-label"
                                                                                id="demo-simple-select"
                                                                                label="Select Description"
                                                                                onChange={(e) => {
                                                                                    setNtaScoreResult((prev) => {
                                                                                        let array = [...prev.hpReportIC10Result]
                                                                                        for (var j of array) {
                                                                                            if (j?.NTA_ICD_COD_Desc !== undefined) {
                                                                                                for (var k of j?.NTA_ICD_COD_Desc) {
                                                                                                    if (k?._id === e.target.value) {
                                                                                                        let checkPoints = MasterNTAICD10List?.find((item) => item?.Category === k?.ComorbidityDes)
                                                                                                        array[i].description = k?.ICD_Code_Desc;
                                                                                                        array[i].icdcode = k?.ICD_Code;
                                                                                                        array[i].Points = checkPoints !== undefined ? checkPoints?.Points : 0
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                        return { ...prev, hpReportIC10Result: array, totalScoreICD10: calculateNtaScore(array) };
                                                                                    })
                                                                                }}
                                                                            >
                                                                                {obj?.NTA_ICD_COD_Desc.map((option, index) => (
                                                                                    <MenuItem key={index} value={option._id}>{option.ICD_Code_Desc}</MenuItem>
                                                                                ))
                                                                                }

                                                                                <MenuItem sx={{ padding: 0 }}>
                                                                                    <Button onClick={(e) => handleResetSelectedOption(e, "singleDiagnisis", i, null)} variant="#86BD3B" color="secondary" sx={{
                                                                                        borderRadius: "0 !important", width: "100%",
                                                                                        mr: 0,
                                                                                        cursor: "pointer"
                                                                                    }}>
                                                                                        <RestartAltIcon sx={{ color: "#86BD3B" }} />
                                                                                        <IconButton sx={{ paddingLeft: "0px", fontSize: "19px" }} />Reset
                                                                                    </Button>
                                                                                </MenuItem>
                                                                            </Select>
                                                                        </FormControl>
                                                                    </div>
                                                                </td>
                                                                : <td>
                                                                    <a
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => handlePdfUrlSearch(obj?.fileName, obj?.description)}
                                                                        href="javascript:void(0)"
                                                                        rel="noopener noreferrer"
                                                                    > {obj?.description}
                                                                    </a>
                                                                    {obj?.section && handleshowSection(obj?.section)}
                                                                </td>
                                                        }
                                                        {obj?.MdsItem ?
                                                            <td className="tooltip_med" style={{ paddingLeft: "16px" }}>
                                                                <Tooltip
                                                                    TransitionComponent={Zoom}
                                                                    TransitionProps={{ timeout: 600 }}
                                                                    placement="right-start"
                                                                    arrow
                                                                    title={
                                                                        <span
                                                                            style={{
                                                                                padding: '4px',
                                                                                fontSize: '13px',
                                                                                backgroundColor: '#efe6f4',
                                                                                width: "auto",
                                                                                color: "#423a3afa",
                                                                                display: "block"
                                                                            }}
                                                                        >
                                                                            <Stack direction={"row"}>
                                                                                <Typography sx={{ color: "#440E66", fontSize: "15px", fontWeight: "500", textDecoration: "underline", paddingBottom: "5px" }} >MDS ITEM :</Typography>
                                                                                <Typography pl={1} sx={{ fontSize: "15px" }}>{obj?.MdsItem?.MDS_ITEM}</Typography>
                                                                            </Stack>
                                                                            <Stack direction={"row"}>
                                                                                <Typography sx={{ color: "#440E66", fontSize: "15px", fontWeight: "500", textDecoration: "underline", paddingBottom: "5px" }} >MDS Items:</Typography>
                                                                                <Typography pl={1} sx={{ fontSize: "15px" }}>{obj?.MdsItem?.Category}</Typography>
                                                                            </Stack>
                                                                        </span>
                                                                    }
                                                                >
                                                                    <IconButton >
                                                                        <InfoIcon />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </td>

                                                            : <td> - </td>}


                                                        <td> {obj?.Points}</td>

                                                        {/* <td>
                            {
                                obj?.MdsItem ?
                                    <input value={obj?.MDS_ITEM_Edit} className="input-field-style" style={{ height: "40px" }} onChange={(e) => {
                                        setNtaScoreResult((prev) => {
                                            let array = [...prev.hpReportIC10Result];
                                            for (let i = 0; i < array.length; i++) {
                                                let j = array[i]
                                                if (j?.MdsItem !== undefined && obj?.icdcode?.substring(0, 3) === j?.MdsItem?.Icd_10_code.substring(0, 3)) {
                                                    array[i].MDS_ITEM_Edit = e.target.value;
                                                    array[i].MDS_ITEM__POINT_Edit = e.target.value === obj?.MdsItem?.MDS_ITEM ? j?.MdsItem?.Points : 0;
                                                }
                                            }
                                            return { ...prev, hpReportIC10Result: array, totalScoreICD10: calculateNtaScore(array) };
                                        });

                                    }} type="text" />
                                    : "-"
                            }

                        </td> */}
                                                        {

                                                        }
                                                        <td>
                                                            {
                                                                obj?.MdsItem ?
                                                                    obj?.MDS_ITEM__POINT_Edit
                                                                    : 0
                                                            }
                                                        </td>
                                                    </tr>
                                                </>

                                        }


                                    </>
                                ))

                            }
                        </tbody>
                    </table>
                </div>
                <div className="col-md-6 mt-5">Total Score : {NtaScoreResult?.totalScoreICD10}</div>
            </div>
            <div className="listing">
                <div class="title d-flex justify-content-between align-items-center">
                    <div>
                        <span>List of all Medication</span>
                        {/* <p className="info bold-text">Total Score: {NtaScoreResult?.totalScoreMedication}</p> */}
                    </div>
                </div>

                <div id="myTable_wrapper" class="dataTables_wrapper no-footer">
                    <table id="myTable" class="display dataTable no-footer" aria-describedby="myTable_info">
                        <thead>
                            <tr>
                                <th className={`sorting`} tabindex="0" aria-controls="myTable" rowspan="1" colspan="1" style={{ width: "150.875px" }}>Name</th>
                                <th className={`sorting`} tabindex="0" aria-controls="myTable" rowspan="1" colspan="1" style={{ width: "310.038px" }}>Dosage</th>
                                <th className={`sorting`} tabindex="0" aria-controls="myTable" rowspan="1" colspan="1" style={{ width: "250.475px" }} >Frequency</th>
                                {/* <th className={`sorting`} tabindex="0" aria-controls="myTable" rowspan="1" colspan="1" style={{ width: "219.012px" }}>StartDate</th>
                                <th className={`sorting`} tabindex="0" aria-controls="myTable" rowspan="1" colspan="1" style={{ width: "219.012px" }}>EndDate</th> */}
                                <th className={`sorting`} tabindex="0" aria-controls="myTable" rowspan="1" colspan="1" style={{ width: "219.012px" }}>Nutritional Approaches</th>
                                {/* <th className={`sorting`} tabindex="0" aria-controls="myTable" rowspan="1" colspan="1" style={{ width: "310.038px" }} >MDS Info</th> */}
                                {/* <th className={`sorting`} tabindex="0" aria-controls="myTable" rowspan="1" colspan="1" style={{ width: "250.475px" }} >Enter MDS ITEM </th>
                                <th className={`sorting`} tabindex="0" aria-controls="myTable" rowspan="1" colspan="1" style={{ width: "219.012px" }}>NTA Points</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {
                                NtaScoreResult?.hpReportMedicationResult?.length > 0 ?
                                    NtaScoreResult?.hpReportMedicationResult?.map((obj, i) => (
                                        <tr class="odd" key={i}>
                                            <a
                                                style={{ cursor: "pointer" }}

                                                onClick={() => handlePdfUrlSearch(obj?.fileName, obj?.Medication)}


                                                href="javascript:void(0)"

                                                rel="noopener noreferrer"
                                            > {obj?.Medication}
                                            </a>
                                            <td>{obj?.Dosage}</td>
                                            <td>{obj?.Frequency}</td>
                                            {/* <td>{obj?.StartDate || "-"}</td>
                                            <td>{obj?.EndDate || "-"}</td> */}
                                            <td>
                                                {obj?.IVType}
                                                <div className="input-field-input">
                                                    <div className="form-group">
                                                        <FormControl fullWidth className="select_des">
                                                            <InputLabel id="demo-simple-select-label">Select</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                label="Select"
                                                                onChange={(e) => {
                                                                    setNtaScoreResult((prev) => {
                                                                        let array = [...NtaScoreResult?.hpReportMedicationResult]
                                                                        for (var j = 0; j < MasterMedicationList.length; j++) {
                                                                            if (e.target.value === MasterMedicationList[j]?._id) {
                                                                                array[i].IVType = MasterMedicationList[j].Category
                                                                                array[i].MDS_Point = ""
                                                                                array[i].MDS_Desc = ""
                                                                            }
                                                                        }
                                                                        return { ...prev, hpReportMedicationResult: array, totalScoreMedication: calculateNtaMedicationScore(array) }
                                                                    })
                                                                }}
                                                            >
                                                                {MasterMedicationList.map((option, index) => (
                                                                    <MenuItem key={index} value={option._id}>{option.Category}</MenuItem>
                                                                ))
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                    </div>

                                                </div>
                                            </td>

                                            {/* <td className={`${checkMedicationOnHover(obj.IVType) && 'tooltip_med'}`} style={{ width: "310.038px" }}>
                                                <Tooltip
                                                    TransitionComponent={Zoom}
                                                    TransitionProps={{ timeout: 600 }}
                                                    placement="right-start"
                                                    arrow
                                                    title={
                                                        checkMedicationOnHover(obj.IVType) && <span
                                                            style={{
                                                                padding: '4px',
                                                                fontSize: '13px',
                                                                backgroundColor: '#efe6f4',
                                                                width: "auto",
                                                                color: "#423a3afa",
                                                                display: "block"
                                                            }}
                                                        >
                                                            {
                                                                renderMedicationInfo(obj?.IVType)
                                                            }
                                                        </span>
                                                    }
                                                >
                                                    <IconButton >
                                                        <InfoIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </td> */}
                                            {/* <td>
                                                <input disabled={!obj.IVType} value={obj.MDS_Desc} className="input-field-style" style={{ marginTop: "13px", height: "40px" }} onChange={(e) => handleChangeMedication(i, obj, e.target.value)} type="text" />
                                            </td> */}

                                            {/* <td>{obj?.MDS_Point}</td> */}
                                        </tr>
                                    ))
                                    :
                                    <td colSpan={12} style={{ textAlign: "center" }}>No Data</td>

                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <NursingComponent nursingComponentResult={NtaScoreResult?.nursingComponentResult} setNtaScoreResult={setNtaScoreResult} NtaScoreResult={NtaScoreResult} handlePdfUrlSearch={handlePdfUrlSearch} />
            </div>
            <Dialog
                isModalOpen={isModalOpen}
                onToggleDialog={(isDialogOpen) => onToggleDialog(isDialogOpen)}
                mode="semi-half"
            >

                <div className="row">

                    <div className="col-md-6 mt-5">
                        <button className="btn btn-outline-success btn-lg mt-4" style={{ width: '22rem', marginLeft: '1rem', margin: "auto" }} onClick={() => onDownloadPDFfile()}>Download Pdf File</button>
                    </div>
                </div>

            </Dialog>
        </>
    )
}


export default PdfPreview;


