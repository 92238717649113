import React, { useRef } from "react";

import ErrorMessage from "./ErrorMessage";

const FileUpload = ({ removeMargin = false, ...props }) => {
    return (
        <React.Fragment>
            <div className="form-field">
                <Input {...props} />
            </div>
        </React.Fragment>
    );
};

export default FileUpload;

const Input = ({
    label,
    children,
    field,
    form,
    className,
    disabled,
    onFileSelected,
    documentName,
    admin,
    multiplefileUpload,
    ...props
}) => {
    let isDisabled = disabled ? "disabled" : "";
    let disabledClass = disabled ? "cursor-not-allowed" : "";
    let fileUploadRef = useRef();

    function handleChange(event) {
        onFileSelected({ path: fileUploadRef.value, file: multiplefileUpload ? fileUploadRef.files : fileUploadRef.files[0] });
        event.target.value = ""

        // switch (documentName) {

        // case "policeReport":
        //     onFileSelected({ path: fileUploadRef.value, file: fileUploadRef.files[0] });
        //     break;
        // case "accidentScenes":
        //     onFileSelected({ path: fileUploadRef.value, file: fileUploadRef.files[0] });
        //     break;
        // case "injuryFile":
        //     onFileSelected({ path: fileUploadRef.value, file: fileUploadRef.files[0] });
        //     break;
        // case "medicalRecordsFile":
        //     onFileSelected({ path: fileUploadRef.value, file: fileUploadRef.files[0] });
        //     break;
        // case "medicalBillFile":
        //     onFileSelected({ path: fileUploadRef.value, file: fileUploadRef.files[0] });
        //     break;
        // case "incomeDocumentFile":
        //     onFileSelected({ path: fileUploadRef.value, file: fileUploadRef.files[0] });
        //     break;
        // }
    }

    return (
        <React.Fragment>
            <label> {label} </label>
            {
                admin ?
                    <div>
                        <input
                            type="file"
                            ref={elementRef => fileUploadRef = elementRef}
                            multiple
                            // {...field}
                            // {...props}
                            className={`input-control file-upload-control ${className ?? ''} ${disabledClass}`}
                            disabled={isDisabled}
                            onChange={handleChange}

                        />
                    </div>
                    :
                    <div className="file_upload_wrap">
                        <input ref={elementRef => fileUploadRef = elementRef} className="govuk-file-upload moj-multi-file-upload__input" id="documents" name="documents" type="file" multiple onChange={handleChange} />
                        <button style={{ backgroundColor: "#1C47D1", width: "100%", color: "white" }} type="submit" className="govuk-button govuk-button--secondary moj-multi-file-upload__button" data-module="govuk-button">
                            Choose file
                        </button>
                    </div>
            }
                 <ErrorMessage form={form} field={field} />
           
            {children}
        </React.Fragment>
    );
};
