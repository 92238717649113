import { Field, Formik, Form } from 'formik'
import * as Yup from 'yup'
import { TextInput } from "../../../components";
import { SuperAdminAuthService } from "../../../api-services/"
import LoggedInUserService from "../../../services/AuthService";
import { Link } from "react-router-dom";
import Constants from '../../../Constants';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
const companyLogo = require("../../../assets/images/logo.png")

const AdminLogin = () => {
    const navigate = useNavigate()
    const [showPassword, setShowPassword] = useState(false);
    const validationSchema = Yup.object().shape({
        email: Yup.string().required('Email is required').email('Please enter a valid email'),
        password: Yup.string().required('Password is required').min(8, 'Password must be at least 8 characters long'),
    })

    const onSubmit = (values, setErrors) => {
        SuperAdminAuthService.Login(values).then((res) => {
            LoggedInUserService.UpdateLoggedInUserSession(res?.data?.token);
            window.location.href = '/admin/list'
        }).catch(err => {
            setErrors({ ...err?.response?.data?.message })
        })
    }

    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                        <h2 className="text-center">Super Admin Login</h2>
                            <img src={companyLogo} className="img-fluid mb-4" alt="company-logo" />
                            <Formik
                                initialValues={{
                                    email: '',
                                    password: '',
                                }}
                                enableReinitialize={true}
                                validationSchema={validationSchema}
                                onSubmit={(values, { setErrors }) => {
                                    onSubmit(values, setErrors)
                                }}
                            >
                                {() => (
                                    <Form>
                                        <div className="form-group">
                                            <Field name="email" removeMargin={true} className="form-control" shouldDollarRender={true} shouldUserRenderIcon={<> <span className="input-group-text" style={{
                                                width: "2.5rem",
                                                outline: "none",
                                                height: "48px",
                                                border: "none",
                                                position: "absolute"
                                            }} id="basic-addon1"><i className="fa-solid fa-user"></i></span></>} placeholder="Enter Email" component={TextInput} />
                                        </div>
                                        <div className="form-group" style={{ position: 'relative' }}>
                                            <Field name="password" removeMargin={true} shouldDollarRender={true} shouldUserRenderIcon={<> <span style={{
                                                width: "2.5rem",
                                                outline: "none",
                                                height: "48px",
                                                border: "none",
                                                position: "absolute"
                                            }} className="input-group-text"><i className="fa-solid fa-key"></i></span></>} className="form-control"  type={showPassword ? "text" : "password"} placeholder="Enter Password" component={TextInput} />
                                               <span
                                                    onClick={()=>setShowPassword(!showPassword)}
                                                    style={{
                                                        position: 'absolute',
                                                        right: '10px',
                                                        top: '27px',
                                                        transform: 'translateY(-50%)',
                                                        cursor: 'pointer'
                                                    }}
                                                >
                                                    <i className={showPassword ? "fa fa-eye" : "fa fa-eye-slash"}></i>
                                                </span>
                                        </div>
                                        <div className="text-center">
                                            <button className="btn-theme btn-login" type="submit">Login</button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdminLogin;
