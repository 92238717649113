import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { TextField, Autocomplete, Stack, Box } from "@mui/material"
import { SuperAdminService } from '../../../api-services'
import PatientList from "../../cases/Patient"
import { Link } from 'react-router-dom'
const BuildingList = () => {
  const [buildingList, setbuildingList] = useState(null);
  const [tokenPayload, settokenPayload] = useState(null)

  useEffect(() => {
    getHospital();
  }, [])



  const getHospital = async () => {
    SuperAdminService.GetHospital(null, null, null, "buildingList").then(resp => {
      setbuildingList(resp.data.reverse());
    }).catch((err) => {

    })
  }

  const findfacilty = async (id) => {
    try {
      let resp = await SuperAdminService.SwitchFacility(id);
      settokenPayload(resp?.token)
    }
    catch (err) {

    }
  }

  const handleOnChangeSelect = async (event, selectedOption) => {
    const { value } = selectedOption != null ? selectedOption : {}
    const id = value?._id;
    if (id) {
      await findfacilty(id)
    }
  };


  const AutoCompleteSearchBuilding = useCallback(() => {
    return (
      <div class={`title d-flex justify-content-between align-items-center`}>
        <Box sx={{ mb: 4 }}> {/* Adding some margin-bottom */}
          <Stack spacing={2} sx={{ width: 300, marginRight: "20px" }}>
            <Autocomplete
              id="free-solo-demo"
              freeSolo
              onChange={handleOnChangeSelect}
              options={buildingList != null ? buildingList?.map((item) => ({ label: item?.hospitalName, value: item })) : []}
              renderInput={(params) => <TextField {...params} onChange={(e) => {
              }} label="Search Facility" />}
            />
          </Stack>
        </Box>

      </div>
    )

  }, [buildingList])

  return (
    <>

      <PatientList switchBuilding={true} tokenPayload={tokenPayload} children={<AutoCompleteSearchBuilding />} />
    </>
  )
}




export default BuildingList